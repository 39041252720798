import styled, { css } from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
`;

export const HeaderLogo = styled.a`
  max-height: 30px;
  ${({ theme }) => css`
    @media(min-width: ${theme.breakpoints.mobile}px) {
      max-height: 40px;
    }
  `};
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
