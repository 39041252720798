import * as LANG from '@/constants/locale';

export const mapLocale = (iso3: string) => {
  switch (iso3) {
    case 'BRA':
      return LANG.POR_LOCALE;
    case 'KAZ':
      return LANG.KAZ_LOCALE;
    case 'RUS':
    case 'ARM':
    case 'AZE':
    case 'BLR':
    case 'KGZ':
    case 'MDA':
    case 'TJK':
    case 'TKM':
    case 'UZB':
    case 'UKR':
      return LANG.RUS_LOCALE;
    default:
      return LANG.ENG_LOCALE;
  }
};
