import styled, { css } from 'styled-components';

export const ErrorMessage = styled.span`
  margin-top: 5px;
  display: block;
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize.two};
    line-height: ${theme.typography.lineHeight.two};
    color: ${theme.colors.red.two};
    font-family: ${theme.typography.fontFamily.navigo};
  `};
`;
