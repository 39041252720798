import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHead } from '@/hooks/useHead';
import { useTranslations } from '@/hooks/useTranslations';
import { selectIsLoading, selectLang } from '@/features/global';
import { clearState, selectRedirectURL } from '@/features/signin';
import { redirect } from '@/utils/redirect';
import { SIGN_IN_PAGE_TITLE } from '@/constants/title';
import LoaderElement from '@/elements/loader';
import ErrorBoundaryElement from '@/elements/error-boundary';
import defaultTheme from '@/assets/styles/theme';
import { ThemeProvider } from 'styled-components';
import { Fonts, GlobalStyle, NormalizeStyle } from '@/assets/styles/base';
import SignInEntry from './entry';

function SignInPage() {
  const translations = useTranslations();
  const isLoading = useSelector(selectIsLoading);
  const redirectURL = useSelector(selectRedirectURL);
  const lang = useSelector(selectLang);
  const dispatch = useDispatch();

  useHead({ translations, lang, title: SIGN_IN_PAGE_TITLE });

  useEffect(() => () => {
    dispatch(clearState());
  }, [dispatch]);

  if (redirectURL) {
    redirect(redirectURL);

    return <LoaderElement fullPage />;
  }

  if (isLoading || !translations) return <LoaderElement fullPage />;

  return (
    <ThemeProvider theme={defaultTheme}>
      <NormalizeStyle />
      <Fonts />
      <GlobalStyle />
      <IntlProvider locale={lang} messages={translations}>
        <ErrorBoundaryElement>
          <SignInEntry />
        </ErrorBoundaryElement>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default SignInPage;
