import styled, { css, keyframes } from 'styled-components';
import { StyledButtonProps } from './types';

const standardScale = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    /* 16px X 0.375 = 6px */
    transform: scale(0.375);
  }
`;

export const LoaderBubble = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => css`
    background: ${theme.colors.green.two};
  `}
`;

export const ButtonLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    ${LoaderBubble} {
      animation: ${standardScale} ${theme.transitions.duration[400]}
        ${theme.transitions.easing.easeInOut} infinite alternate-reverse;

      &:nth-child(even) {
        animation-direction: alternate;
      }
    }
  `}
`;

export const ButtonText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white.one};
  `};
`;

export const Button = styled.button<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  height: 60px;
  padding: 15px 40px;
  border-radius: 50px;
  ${({ theme }) => css`
    background-color: ${theme.colors.green.one};
    &:hover {
      background-color: ${theme.colors.green.two};
    }
    @media (min-width: ${theme.breakpoints.mobile}px) {
      width: max-content;
      margin-top: auto;
    }
  `};

  ${({ loading, theme }) => loading
    && css`
      background-color: ${theme.colors.gray.two};

      &:hover {
        background-color: ${theme.colors.gray.two};
      }

      ${ButtonText} {
        visibility: hidden;
      }
    `};
`;
