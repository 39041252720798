import { createGlobalStyle } from 'styled-components';

const GlobalStyleNew = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    min-width: 320px;
    overflow-x: hidden;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    background-color: #F5F6F9;
    ${({ theme }) => theme.typography.text13};
    ${({ theme }) => theme.palette.black[15]};
  }
`;

export default GlobalStyleNew;
