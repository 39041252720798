import { Page } from '@/types/page';
import {
  checkboxFieldOne,
  checkboxFieldTwo,
  emailField,
  emailSignIn,
  passwordField,
  newPasswordField,
  phoneField,
} from './rules';
import { validate } from './validate';

export const createValidate = (page?: Page) => {
  const common = {
    fields: {
      email: emailField,
      password: passwordField,
      newPassword: newPasswordField,
      country: () => {},
      code: () => {},
      phone: phoneField,
      login: emailField,
      checkbox: {
        one: checkboxFieldOne,
        two: checkboxFieldTwo,
      },
    },
    validate,
  };

  switch (page) {
    case Page.signin:
      return { ...common, fields: { ...common.fields, email: emailSignIn } };
    default:
      return common;
  }
};
