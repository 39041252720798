const black = {
  main: '#292E3D',
  one: '#1d1d39',
  secondary: '#333',
  disabled: '#818998',
};

const blue = {
  one: '#039be6',
  two: '#38c4ff',
  three: '#2671D9',
  four: '#6c9ee5',
  five: '#3A64F8',
  six: '#6B8BFA',
};

const green = {
  one: '#03973d',
  two: '#27bc62',
};

const red = {
  one: '#ea3441',
  two: '#FF3333',
  error: '#FA3848',
};

const gray = {
  main: '#F9FAFB',
  one: '#e7eaf1',
  two: '#d9dde4',
  three: '#f1f1f4',
  four: '#C2C7D6',
  five: '#F8F9FD',
  six: '#A3ABC2',
  seven: '#525C7A',
};

const white = {
  one: '#fff',
};

export const colors = {
  black,
  blue,
  green,
  red,
  gray,
  white,
};
