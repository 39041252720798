import React, { ReactNode } from 'react';

import * as Styled from './Field.styled';

type Props = {
  text: string
  children: ReactNode
};

const Field = ({ text, children }: Props) => (
  <Styled.Field>
    <Styled.Label>{text}</Styled.Label>
    {children}
  </Styled.Field>
);

export default Field;
