import React from 'react';

export const Telegram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.904297" y="0.902344" width="23" height="23" rx="11.5" fill="white" />
    <g clipPath="url(#clip0_5347_5953)">
      <path d="M12.4043 18.9038C15.9941 18.9038 18.9043 15.9937 18.9043 12.4038C18.9043 8.81396 15.9941 5.90381 12.4043 5.90381C8.81445 5.90381 5.9043 8.81396 5.9043 12.4038C5.9043 15.9937 8.81445 18.9038 12.4043 18.9038Z" fill="url(#paint0_linear_5347_5953)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.8466 12.3358C10.7415 11.5102 12.005 10.9659 12.6373 10.703C14.4424 9.95216 14.8175 9.82174 15.0619 9.81743C15.1157 9.81648 15.2359 9.82981 15.3138 9.893C15.3796 9.94636 15.3977 10.0184 15.4063 10.069C15.415 10.1196 15.4258 10.2349 15.4172 10.3249C15.3194 11.3527 14.8961 13.8469 14.6808 14.9981C14.5897 15.4852 14.4102 15.6485 14.2366 15.6645C13.8591 15.6992 13.5725 15.415 13.2069 15.1754C12.6348 14.8004 12.3116 14.5669 11.7563 14.201C11.1145 13.7781 11.5305 13.5456 11.8963 13.1658C11.992 13.0664 13.6551 11.5536 13.6873 11.4164C13.6913 11.3992 13.6951 11.3352 13.6571 11.3015C13.6191 11.2677 13.563 11.2792 13.5225 11.2884C13.4651 11.3014 12.5512 11.9055 10.7808 13.1006C10.5213 13.2787 10.2864 13.3655 10.0759 13.361C9.84377 13.356 9.39733 13.2298 9.06546 13.1219C8.65839 12.9896 8.33487 12.9196 8.36304 12.6949C8.37771 12.5778 8.5389 12.4581 8.8466 12.3358Z" fill="#F5F7FF" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_5347_5953" x1="12.4043" y1="5.90381" x2="12.4043" y2="18.8074" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2AABEE" />
        <stop offset="1" stopColor="#229ED9" />
      </linearGradient>
      <clipPath id="clip0_5347_5953">
        <rect width="13" height="13" fill="white" transform="translate(5.9043 5.90234)" />
      </clipPath>
    </defs>
  </svg>

);
