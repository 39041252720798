import { sendAnalytics, sendEventSuccessReg, pushAnalytics } from './send-analytics';

export function createAnalytics() {
  return {
    sendAnalytics,
    sendEventSuccessReg,
    pushAnalytics,
  };
}

export const getClientId = (cookie: string) => {
  let match = cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const raw = match ? decodeURIComponent(match[1]) : null;

  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }

  return match ? match[1] : '';
};

export function startAnalytics() {
  if (!Array.isArray(window.dataLayer)) window.dataLayer = [];

  // activate google optimize
  window.dataLayer.push({ event: 'optimize.activate' });
  window.dataLayer.push({ event: 'universal_event', universal_event_systems: 'google_analytics' });
}
