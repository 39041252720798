import React from 'react';

export function YandexSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#FC3F1D"
      />
      <path
        d="M11.3333 5.66667H10.4167C8.70833 5.66667 7.83333 6.54167 7.83333 7.79167C7.83333 9.20833 8.45833 9.875 9.70833 10.75L10.75 11.4583L7.75 15.9583H5.5L8.20833 11.9583C6.66667 10.8333 5.79167 9.75 5.79167 7.95833C5.79167 5.66667 7.375 4.125 10.4167 4.125H13.4167V16H11.3333V5.66667V5.66667Z"
        fill="white"
      />
    </svg>
  );
}
