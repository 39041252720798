import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createAnalytics } from '@/services/analytics';
import { ROUTE_SIGN_IN } from '@/constants/routes';
import { REGISTER_HAVE_ACCOUNT } from '@/constants/analytics';
import { LinkButtonElement } from '../links';
import { RightComponent } from './right-component.styled';

const { sendAnalytics } = createAnalytics();

function SignUpRightComponent() {
  const handleClick = useCallback(() => {
    sendAnalytics(REGISTER_HAVE_ACCOUNT);
  }, []);

  return (
    <>
      <RightComponent>
        <FormattedMessage id="SignUpForm.headerText" />
      </RightComponent>
      <LinkButtonElement href={ROUTE_SIGN_IN} text="SignUpForm.headerLink" onClick={handleClick} />
    </>
  );
}

export default SignUpRightComponent;
