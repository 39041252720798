import styled, { css } from 'styled-components';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ${({ theme }) => css`
    span {
      font-size: ${theme.typography.fontSize.two};
      line-height: ${theme.typography.lineHeight.four};
    }
  `}
  }
`;

export const TitleH1 = styled.h1<{ withSubtitle?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;

  ${({ theme, withSubtitle }) => css`
    font-size: ${theme.typography.fontSize.four};
    line-height: ${theme.typography.lineHeight.five};
    font-weight: ${theme.typography.fontWeight.three};
    @media (min-width: ${theme.breakpoints.mobile}px) {
      margin-bottom: ${withSubtitle ? '10px' : '30px'};
      font-size: ${theme.typography.fontSize.five};
      line-height: ${theme.typography.lineHeight.six};
    }
  `};
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black.secondary};
    font-size: ${theme.typography.fontSize.one};
    line-height: ${theme.typography.lineHeight.one};
    font-weight: ${theme.typography.fontWeight.two};
    margin-bottom: 7px;

    @media (max-width: ${theme.breakpoints.mobile}px) {
      display: none;
    }
  `};
`;
