import styled, { css } from 'styled-components';

export const Agreements = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: ${theme.breakpoints.mobile}px) {
      margin-bottom: 40px;
    }
  `};
`;
