import styled, { css } from 'styled-components';

export const LinkSocial = styled.a`
  ${({ theme }) => css`
    color: inherit;
    border-radius: ${theme.border.radius.one};
    border: 1px solid ${theme.colors.gray.one};
    transition: border-color ${theme.transitions.duration['200']} ${theme.transitions.easing.easeInOut};
    &:hover {
      border-color: ${theme.colors.blue.one};
    }
  `};
`;

export const LinkSocialContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LinkSocialText = styled.span`
  margin-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
