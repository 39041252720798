import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectFullUrlParams } from '@/features/global';
import { Link } from './link.styled';
import { PropsTypes } from './types';

function LinkElement({
  className, text, href, name, onClick,
}: PropsTypes) {
  const fullUrlParams = useSelector(selectFullUrlParams);

  return (
    <Link
      className={className}
      to={{ pathname: href, search: fullUrlParams }}
      onClick={onClick}
      data-name={name}
    >
      <FormattedMessage id={text} />
    </Link>
  );
}

export default LinkElement;
