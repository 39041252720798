import { PayloadAction } from '@reduxjs/toolkit';
import { createValidate } from '@/services/validate';
import { createAnalytics } from '@/services/analytics';
import { createUrl } from '@/services/url';
import { getStateUrl } from '@/utils/get-state';
import { CRITICAL_ERROR } from '@/constants/error';
import { NEW_ELAMA_URL } from '@/constants/urls';
import { ROUTE_TWOFA } from '@/constants/routes';
import { KEY_LOCALE } from '@/constants/locale';
import { AUTH_SUCCESS, AUTH_ERROR } from '@/constants/analytics';
import { Page } from '@/types/page';
import {
  ActionValuesTypes,
  InitialStateTypes,
  ActionErrorsTypes,
  ActionSuccessAuthTypes,
  ActionErrorAuthTypes,
  ActionSuccessCaptchaTypes,
} from './types';

const { sendAnalytics } = createAnalytics();
const { fields } = createValidate(Page.signin);
const { locale } = getStateUrl();

export const addValues = (state: InitialStateTypes, action: PayloadAction<ActionValuesTypes>) => {
  const { name, value } = action.payload;

  state.values[name] = value;

  if (state.errors[name]) state.errors[name] = '';
};

export const addErrors = (state: InitialStateTypes, action: PayloadAction<ActionErrorsTypes>) => {
  const name = action.payload;

  state.errors = {
    ...state.errors,
    [name]: fields[name](state.values[name] as string),
  };
};

export const startAuth = (state: InitialStateTypes) => {
  state.loadFetch = true;
};

export const successAuth = (
  state: InitialStateTypes,
  action: PayloadAction<ActionSuccessAuthTypes>,
) => {
  let { backUrl } = action.payload;
  const { checkPathname, setQuery } = createUrl({ url: backUrl });

  if (checkPathname({ pathname: ROUTE_TWOFA })) {
    backUrl = setQuery({ queryKey: KEY_LOCALE, queryValue: locale });
  }

  sendAnalytics(AUTH_SUCCESS);

  state.redirectURL = backUrl || NEW_ELAMA_URL;
};

export const errorAuth = (
  state: InitialStateTypes,
  action: PayloadAction<ActionErrorAuthTypes>,
) => {
  sendAnalytics(AUTH_ERROR);

  state.loadFetch = false;
  state.formError = `Form.error.${action.payload || CRITICAL_ERROR}`;
};

export const stateClear = (state: InitialStateTypes) => {
  state.values.email = '';
  state.values.password = '';
  state.errors.email = '';
  state.errors.password = '';
  state.loadFetch = false;
  state.formError = '';
  state.redirectURL = '';
};

export const captchaSuccess = (
  state: InitialStateTypes,
  action: PayloadAction<ActionSuccessCaptchaTypes>,
) => {
  state.isCaptcha = action.payload;
};

export const captchaError = (state: InitialStateTypes) => {
  console.warn('captchaStatus request error');
  state.isCaptcha = true;
};
