import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/app/store';
import { createFormData } from '@/utils/create-form-data';
import { updatePassword } from '@/api/update-password';
import { HTTP_STATUS_OK } from '@/constants/api';
import { RootSignInState, ValuesTypes } from './types';
import {
  addValues,
  addErrors,
  startUpdate,
  successUpdate,
  errorUpdate,
  stateClear,
} from './actions';
import { initialState } from './state';

const updateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    getValues: addValues,
    getErrors: addErrors,
    updateStart: startUpdate,
    updateSuccess: successUpdate,
    updateError: errorUpdate,
    clearState: stateClear,
  },
});

export const {
  getValues,
  getErrors,
  updateStart,
  updateSuccess,
  updateError,
  clearState,
} = updateSlice.actions;

export const updateName = updateSlice.name;

export const fetchUpdate = (
  values: ValuesTypes,
  fullUrlParams: string,
  recaptchaToken: string,
): AppThunk => async (dispatch: AppDispatch) => {
  const formData = createFormData({ values, fullUrlParams, recaptchaToken });

  dispatch(updateStart());

  try {
    const success = await updatePassword(formData);

    if (success.status !== HTTP_STATUS_OK) throw new Error('');

    dispatch(updateSuccess());
  } catch (err) {
    dispatch(updateError({ status: err.response?.status, text: err.response?.data?.code }));
  }
};

export const selectNewPassword = (state: RootSignInState) => state.update.values.newPassword;
export const selectValues = (state: RootSignInState) => state.update.values;
export const selectErrors = (state: RootSignInState) => state.update.errors;
export const selectLogin = (state: RootSignInState) => state.update.values.login;
export const selectIsSubmitting = (state: RootSignInState) => state.update.isSubmitting;
export const selectIsErrorUpdate = (state: RootSignInState) => state.update.isErrorUpdate;
export const selectFormError = (state: RootSignInState) => state.update.formError;
export const selectIsDisableButton = (state: RootSignInState) => state.update.isDisableButton;
export const selectLoadFetch = (state: RootSignInState) => state.update.loadFetch;
export const selectConfirmationData = (state: RootSignInState) => ({
  login: state.update.values.login,
  code: state.update.values.confirmationCode,
});

export default updateSlice.reducer;
