import React from 'react';
import { useHead } from '@/hooks/useHead';
import { UPDATE_PAGE_TITLE } from '@/constants/title';
import { theme } from '@elamajs/ui-kit';
import { ThemeProvider } from 'styled-components';
import { useTranslations } from '@/hooks/useTranslations';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Header from '../../components/Header';
import RightPanel from '../../components/RightPanel';
import UpdateContent from '../../components/UpdateContent';
import GlobalStyleNew from '../../styles/_global';

import * as Styled from './Update.styled';

const UpdatePage = () => {
  const translations = useTranslations();
  useHead({ translations, lang: 'ru', title: UPDATE_PAGE_TITLE });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyleNew />
      <ErrorBoundary>
        <Styled.Content>
          <Header />
          <RightPanel isCenter>
            <UpdateContent />
          </RightPanel>
        </Styled.Content>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default UpdatePage;
