import styled, { css } from 'styled-components';

export const ErrorBoundary = styled.section`
  position: relative;
  padding: 120px 90px 30px;
  height: 100vh;
  ${({ theme }) => css`
    color: ${theme.palette.black[15]};
    background-color: ${theme.palette.white};
  `};

  @media (max-width: 1059px) {
    padding: 120px 15px 30px;
  }
`;

export const ErrorBoundaryH2 = styled.h2`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 30px;
`;

export const ErrorBoundaryP = styled.p`
  margin: 0;
  ${({ theme }) => css`
    font-size: ${theme.typography.text15};
    color: ${theme.palette.black[15]};
  `};
`;
