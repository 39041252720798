export const PUBLIC_URL = '';

export const NEW_ELAMA_URL = 'https://my.elama.global/';

export const INFOSITE_URL = 'https://elama.ru';

export const INFOSITE_URLS = {
  ru: 'https://elama.ru',
  ru_KZ: 'https://elama.kz',
  en: 'https://elama.global',
  pt_BR: 'https://elama.com.br',
};

export const SOCIAL_URLS = {
  yandex: 'https://account.elama.global/connect/yandex',
  google: 'https://account.elama.global/connect/google',
};

export const OFFER_AGREEMENT = 'https://elama.ru/legal_docs/pd/consent_registration.pdf';

export const OFFER_LINK_PRIVATE_POLICY_RUS = 'https://yandex.ru/legal/confidential/';
export const OFFER_LINK_PRIVATE_POLICY_KAZ = 'https://elama.kz/legal_docs/pd/consent_adv-kz.pdf';
export const OFFER_LINK_PRIVATE_POLICY_INT = 'https://elama.global/legal_docs/tou/ss/tou_customers.pdf';

export const PERSONAL_DATA_URL_RUS = 'https://elama.ru/legal_docs/tou/ss/oferta-advservice.pdf';
export const PERSONAL_DATA_URL_KAZ = 'https://elama.ru/legal_docs/tou/ss/oferta-advservice.pdf';
export const PERSONAL_DATA_URL_WORLD = 'https://elama.global/legal_docs/pd/privacy_policy.pdf';

export const AGENCY_REGISTRATION_URL = 'https://agency.elama.global/signup';
