import * as yup from 'yup';
import {
  UPPERCASE_REGEX, LOWERCASE_REGEX, DECIMAL_REGEX, SPECIAL_CHARACTERS_REGEX,
} from '@/constants/validate';

export const FIELD = {
  PASSWORD: 'newPassword',
} as const;

export type FormData = {
  [FIELD.PASSWORD]: string
};

export const INITIAL_VALUES: FormData = {
  [FIELD.PASSWORD]: '',
};

export const SCHEMA = yup.object().shape({
  newPassword: yup.string()
    .required('Обязательное поле')
    .min(8, 'Пароль должен содержать минимум 8 символов')
    .max(50, 'Значение слишком длинное, должно быть  равно 50 символам или меньше')
    .test('newPassword', 'Пароль должен содержать хотя бы одну заглавную букву латинского алфавита', (value) => UPPERCASE_REGEX.test(value))
    .test('newPassword', 'Пароль должен содержать хотя бы одну строчную букву латинского алфавита', (value) => LOWERCASE_REGEX.test(value))
    .test('newPassword', 'Пароль должен содержать хотя бы одну цифру', (value) => DECIMAL_REGEX.test(value))
    .test('newPassword', 'Пароль должен содержать хотя бы один спецсимвол', (value) => SPECIAL_CHARACTERS_REGEX.test(value)),
});
