import { EVENT_ACTIONS, EVENT_CATEGORIES } from '@/constants/analytics';
import { ConfigTypes } from '@/types/analytics';

export function authError(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginWrongEmailPassword,
  };
}

export function authSuccess(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginClickedSubmitForm,
  };
}

export function authSocialError(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginSocialError,
  };
}

export function loginGoogle(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginChoiceGoogle,
  };
}

export function loginYandex(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginChoiceYandex,
  };
}

export function loginForgotPassword(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.login,
    event_action: EVENT_ACTIONS.loginForgotPassword,
  };
}

export function registerError(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpError,
  };
}

export function registerSuccess(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpSuccessReg,
  };
}

export function registerSocialError(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpSocialError,
  };
}

export function registerGoogle(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpChoiceGoogle,
  };
}

export function registerYandex(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpChoiceYandex,
  };
}

export function registerAgencyReg(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpAgencyReg,
  };
}

export function registerHaveAccount(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.signUp,
    event_action: EVENT_ACTIONS.signUpHaveAccount,
  };
}

export function restoreClickLogin(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.restore,
    event_action: EVENT_ACTIONS.restoreClickLogin,
  };
}

export function restoreClickSignup(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.restore,
    event_action: EVENT_ACTIONS.restoreClickSignup,
  };
}

export function restoreSuccess(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.restore,
    event_action: EVENT_ACTIONS.restoreSuccess,
  };
}

export function restoreNotReceived(config: ConfigTypes) {
  return {
    ...config,
    event_category: EVENT_CATEGORIES.restore,
    event_action: EVENT_ACTIONS.restoreNotReceived,
  };
}
