import styled, { css } from 'styled-components';

export const Submit = styled.div`
  a {
    justify-self: center;
  }
`;

export const SubmitError = styled.div`
  color: #FF3333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.navigo};
  `};
`;
