import styled, { css } from 'styled-components';

export const Line = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  ${({ theme }) => css`
      color: ${theme.colors.gray.four};
    `};
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    ${({ theme }) => css`
      background-color: ${theme.colors.gray.four};
    `};
  }

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.mobile}px) {
      justify-content: center;
      margin-bottom: 40px;
    }
  `};
`;

export const LineText = styled.span`
  position: relative;
  padding: 0 10px;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.navigo};
    font-size: ${theme.typography.fontSize.two};
    line-height: ${theme.typography.lineHeight.three};
    background-color: ${theme.colors.gray.five};
    color: ${theme.colors.gray.four};
  `};
`;
