import styled, { css } from 'styled-components';

export const Form = styled.form`
  max-width: 540px;
  width: 100%;
  margin: 40px auto 30px;
  ${({ theme }) => css`
    background-color: ${theme.colors.gray.five};
    @media(max-width: ${theme.breakpoints.mobile}px) {
      padding: 50px 15px;
    }
  `};
  padding: 50px;
  border-radius: 10px;
`;

export const FormGrid = styled.div`
  display: flex;
  flex-direction: column;
`;
