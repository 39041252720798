import { InitialStateTypes } from './types';

export const initialState: InitialStateTypes = {
  values: { email: '', password: '' },
  errors: { email: '', password: '' },
  loadFetch: false,
  isCaptcha: true,
  formError: '',
  redirectURL: '',
};
