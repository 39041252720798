import React, { ReactNode } from 'react';

import * as Styled from './RightPanel.styled';

export type Props = {
  children: ReactNode
  isCenter?: boolean
};

const RightPanel = ({ children, isCenter }: Props) => (
  <Styled.Container isCenter={isCenter}>
    {children}
  </Styled.Container>
);

export default RightPanel;
