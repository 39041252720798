import * as yup from 'yup';
import { isEmail } from '@/utils/is-email';

export const FIELD = {
  LOGIN: 'login',
} as const;

export type FormData = {
  [FIELD.LOGIN]: string
};

export const INITIAL_VALUES: FormData = {
  [FIELD.LOGIN]: '',
};

export const SCHEMA = yup.object().shape({
  login: yup.string()
    .required('Введите почту')
    .test('login', 'Некорректный email', (value) => isEmail(value)),
});
