import React, { PureComponent } from 'react';
import { PropsTypes, StateTypes } from '@/elements/error-boundary/types';
import Header from '../Header';

import * as Styled from './ErrorBoundary.styled';

class ErrorBoundaryElement extends PureComponent<PropsTypes, StateTypes> {
  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Styled.ErrorBoundary>
          <Header />
          <Styled.ErrorBoundaryH2>
            Все в порядке, на сайте ведутся плановые работы.
          </Styled.ErrorBoundaryH2>
          <Styled.ErrorBoundaryP>
            Пожалуйста, попробуйте позже.
          </Styled.ErrorBoundaryP>
        </Styled.ErrorBoundary>
      );
    }

    return children;
  }
}

export default ErrorBoundaryElement;
