import { FORM_DATA_PARAMS, BACK_URL } from '@/constants/params';
import { getClientId } from '@/services/analytics';
import { getParamsObject } from '@/utils/get-params-object';
import { PropsTypes, ReturnTypes } from '@/types/form-data';

export const createFormData = ({
  values,
  fullUrlParams,
  recaptchaToken,
}: PropsTypes): ReturnTypes => {
  const gci = getClientId(document.cookie);
  const recaptcha = { 'g-recaptcha-response': recaptchaToken };
  const backUrl = new URLSearchParams(fullUrlParams).get(BACK_URL) || '';
  const userUrlParams = getParamsObject(FORM_DATA_PARAMS, fullUrlParams);

  return {
    gci,
    backUrl,
    ...userUrlParams,
    ...values,
    ...recaptcha,
  };
};
