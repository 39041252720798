import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import { startAnalytics } from '@/services/analytics';
import {
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_RESTORE_PASSWORD,
  ROUTE_UPDATE_PASSWORD,
} from '@/constants/routes';
import { PUBLIC_URL } from '@/constants/urls';
import SignInPage from '@/pages/signin';
import SignUpPage from '@/pages/signup';
import store from './store';

import RestorePageNew from '../new/pages/Restore';
import UpdatePageNew from '../new/pages/Update';

function App() {
  useEffect(() => {
    startAnalytics();
  }, []);

  return (
    <Provider store={store}>
      <Router basename={PUBLIC_URL}>
        <Switch>
          <Route path={ROUTE_SIGN_IN} exact component={SignInPage} />
          <Route
            path={ROUTE_SIGN_UP}
            exact
            component={SignUpPage}
          />
          <Route path={ROUTE_RESTORE_PASSWORD} exact component={RestorePageNew} />
          <Route path={ROUTE_UPDATE_PASSWORD} exact component={UpdatePageNew} />
          <Redirect to={ROUTE_SIGN_IN} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
