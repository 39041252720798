import { PayloadAction } from '@reduxjs/toolkit';
import { InitialStateTypes, ActionLoadingSuccessTypes } from './types';

export const loadingSuccess = (
  state: InitialStateTypes,
  action: PayloadAction<ActionLoadingSuccessTypes>,
) => {
  state.translations = action.payload;
  state.isLoading = false;
};

export const loadingFailed = (state: InitialStateTypes) => {
  state.translations = {};
  state.isLoading = false;
};
