import { PayloadAction } from '@reduxjs/toolkit';
import { createValidate } from '@/services/validate';
import { HTTP_STATUS_INVALID_DATA } from '@/constants/api';
import { CRITICAL_ERROR } from '@/constants/error';
import {
  ActionValuesTypes,
  InitialStateTypes,
  ActionErrorsTypes,
  ActionFetchErrorTypes,
} from './types';

const { fields, validate } = createValidate();

export const addValues = (state: InitialStateTypes, action: PayloadAction<ActionValuesTypes>) => {
  const { name, value } = action.payload;

  state.isDisableButton = !validate.update({ ...state.values, [name]: value });
  state.values[name] = value;

  if (state.errors[name]) state.errors[name] = '';
};

export const addErrors = (state: InitialStateTypes, action: PayloadAction<ActionErrorsTypes>) => {
  const name = action.payload;

  state.errors = {
    ...state.errors,
    [name]: fields[name](state.values[name] as string),
  };
  state.isDisableButton = !validate.update(state.values);
};

export const startUpdate = (state: InitialStateTypes) => {
  state.loadFetch = true;
};

export const successUpdate = (state: InitialStateTypes) => {
  state.isSubmitting = true;
  state.loadFetch = false;
};

export const errorUpdate = (
  state: InitialStateTypes,
  action: PayloadAction<ActionFetchErrorTypes>,
) => {
  state.loadFetch = false;

  if (action.payload.status === HTTP_STATUS_INVALID_DATA) {
    state.isErrorUpdate = true;
  } else {
    state.formError = `Form.error.${action.payload.text || CRITICAL_ERROR}`;
  }
};

export const stateClear = (state: InitialStateTypes) => {
  state.values.newPassword = '';
  state.values.login = '';
  state.values.confirmationCode = '';
  state.errors.newPassword = '';
  state.isSubmitting = false;
  state.loadFetch = false;
  state.isErrorUpdate = false;
  state.formError = '';
};
