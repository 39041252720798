import { getStateUrl } from '@/utils/get-state';
import { getAutoData } from '@/utils/get-auto-data';
import { AUTO_EMAIL, AUTO_PHONE } from '@/constants/auto-data';
import { InitialStateTypes } from './types';

const { country } = getStateUrl();

export const initialState: InitialStateTypes = {
  values: {
    email: getAutoData(AUTO_EMAIL),
    country,
    phone: getAutoData(AUTO_PHONE),
  },
  checkbox: {
    one: false,
    two: false,
  },
  errorsValues: {
    email: '',
    country: '',
    phone: '',
  },
  errorsCheckbox: {
    one: '',
    two: '',
  },
  loadFetch: false,
  isDisableButton: true,
  redirectURL: '',
  formError: '',
  formCriticalError: '',
};
