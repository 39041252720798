import { isValidPhoneNumber } from 'react-phone-number-input';
import { CheckboxTypes } from '@/features/signup/types';
import { isRuLocale } from '@/utils/is-locale';
import { mapLocale } from '@/maps/mapLocale';
import { isEmail } from '@/utils/is-email';
import {
  UPPERCASE_REGEX,
  LOWERCASE_REGEX,
  DECIMAL_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from '@/constants/validate';

export const emailField = (email?: string) => {
  let error = '';

  if (!email) {
    error = 'Form.schema.required';
  }
  if (!isEmail(email)) {
    error = 'Form.schema.email.notValid';
  }

  return error;
};

export const emailSignIn = (email?: string) => {
  let error = '';

  if (!email) {
    error = 'Form.schema.required';
  }

  return error;
};

export const phoneField = (phone: string) => (!isValidPhoneNumber(phone) ? 'Form.schema.phone.notValid' : '');

export const checkboxFieldOne = (checkbox: CheckboxTypes) => {
  let error = '';

  if (!checkbox.one) {
    error = 'Form.schema.agreement';
  }

  return error;
};

export const checkboxFieldTwo = (checkbox: CheckboxTypes, country: string) => {
  let error = '';

  if (!checkbox.two && !isRuLocale(mapLocale(country))) {
    error = 'Form.schema.agreement';
  }

  return error;
};

export const passwordField = (password: string) => {
  let error = '';

  if (!password) {
    error = 'Form.schema.required';
  } else
  // Для обратной совместимости со старыми паролями (используется на странице логина)
  if (password.length < 3) {
    error = 'Form.schema.password.min';
  } else if (password.length > PASSWORD_MAX_LENGTH) {
    error = 'Form.schema.password.max';
  }

  return error;
};

export const newPasswordField = (password: string) => {
  let error = '';

  if (!password) {
    error = 'Form.schema.required';
  } else if (password.length < PASSWORD_MIN_LENGTH) {
    error = 'Form.schema.password.minimun';
  } else if (password.length > PASSWORD_MAX_LENGTH) {
    error = 'Form.schema.password.max';
  } else if (!UPPERCASE_REGEX.test(password)) {
    error = 'Form.schema.password.noUppercase';
  } else if (!LOWERCASE_REGEX.test(password)) {
    error = 'Form.schema.password.noLowercase';
  } else if (!DECIMAL_REGEX.test(password)) {
    error = 'Form.schema.password.noDecimal';
  } else if (!SPECIAL_CHARACTERS_REGEX.test(password)) {
    error = 'Form.schema.password.noSpecial';
  }

  return error;
};
