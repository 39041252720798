import styled from 'styled-components';

export const Body = styled.div`
  width: 490px;
  padding: 40px;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: max-content;

  button {
    justify-content: center;
  }

  @media (max-width: 519px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const Text = styled.p`
  margin: 0 0 20px;
`;

export const Attention = styled.div`
  margin-bottom: 30px;
`;

export const Loader = styled.div`
  align-self: center;
`;
