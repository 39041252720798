import React, { RefObject, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSignUp } from '@/hooks/useSignUp';
import {
  RECAPTCHA_ENABLED,
  RECAPTCHA_BADGE,
  REACT_APP_RECAPTCHA_PUBLIC_KEY,
  RECAPTCHA_SIZE,
  RECAPTCHA_THEME,
} from '@/constants/re-captcha';
import { Form, FormGrid } from './form.styled';
import { PropsTypes } from './types';

function SignUpForm({ className, children }: PropsTypes) {
  const recaptchaRef: RefObject<never> = useRef(null);
  const [lang, submit] = useSignUp(recaptchaRef);

  return (
    <Form className={className} noValidate onSubmit={submit}>
      <FormGrid>
        {children}
      </FormGrid>
      {RECAPTCHA_ENABLED && (
        <ReCAPTCHA
          ref={recaptchaRef}
          hl={lang}
          size={RECAPTCHA_SIZE}
          theme={RECAPTCHA_THEME}
          sitekey={REACT_APP_RECAPTCHA_PUBLIC_KEY}
          badge={RECAPTCHA_BADGE}
        />
      )}
    </Form>
  );
}

export default SignUpForm;
