import React from 'react';
import { useSelector } from 'react-redux';
import { Page } from '@/types/page';
import { selectFormError } from '@/features/signin';
import { useSocialError } from '@/hooks/useSocialError';
import TitleElement from '@/elements/title';

function SignInTitle() {
  const formError = useSelector(selectFormError);
  const [socialError] = useSocialError(Page.signin);

  return <TitleElement text="SignInForm.title" formError={formError} socialError={socialError} />;
}

export default SignInTitle;
