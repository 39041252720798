import React from 'react';

export const Systems = () => (
  <svg width="69" height="23" viewBox="0 0 69 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M69 11.5C69 17.8513 63.8513 23 57.5 23C51.1487 23 46 17.8513 46 11.5C46 5.14873 51.1487 0 57.5 0C63.8513 0 69 5.14873 69 11.5ZM21.3028 5.48448C19.2793 2.19435 15.6459 0.000488281 11.5 0.000488281C5.14873 0.000488281 0 5.14921 0 11.5005C0 17.8518 5.14873 23.0005 11.5 23.0005C15.646 23.0005 19.2796 20.8064 21.3031 17.5161C20.4663 15.6835 20 13.6462 20 11.5C20 9.35403 20.4662 7.31694 21.3028 5.48448ZM34.5 0C38.646 0 42.2795 2.19395 44.3029 5.4842C43.4662 7.31673 43 9.35392 43 11.5C43 13.6461 43.4662 15.6833 44.3029 17.5158C42.2795 20.806 38.646 23 34.5 23C28.1487 23 23 17.8513 23 11.5C23 5.14873 28.1487 0 34.5 0Z" fill="white" />
    <path d="M6.42158 17.2123C5.87089 17.008 5.40999 16.581 5.25436 16.1354C4.94908 15.2938 4.92514 14.7925 5.15858 14.2541C5.21844 14.1118 5.69132 13.233 6.21208 12.3047C6.72686 11.3826 7.15783 10.5843 7.16382 10.5409C7.1698 10.4914 7.73845 9.44555 8.42682 8.20782C9.74967 5.82521 9.95917 5.52815 10.5039 5.25585C11.1444 4.93405 11.9285 4.94642 12.545 5.29299C12.9461 5.51578 13.389 6.03562 13.5387 6.45026C13.6943 6.87108 13.7063 7.63847 13.5746 8.02835C13.5267 8.17688 12.4672 10.1139 11.2222 12.348C9.66587 15.1453 8.88772 16.482 8.70815 16.6615C8.30111 17.0947 7.88211 17.2741 7.23565 17.2927C6.84657 17.3051 6.61313 17.2865 6.42158 17.2123Z" fill="#FBBC04" />
    <path d="M10.7517 5.10854C10.201 5.31276 9.74007 5.73978 9.58444 6.18536C9.27916 7.02701 9.25522 7.52829 9.48866 8.0667C9.54852 8.20904 10.0214 9.08782 10.5422 10.0161C11.0569 10.9382 11.4879 11.7365 11.4939 11.7799C11.4999 11.8294 12.0685 12.8753 12.7569 14.113C14.0797 16.4956 14.2893 16.7926 14.834 17.0649C15.4744 17.3868 16.2586 17.3744 16.8751 17.0278C17.2762 16.805 17.7191 16.2852 17.8687 15.8705C18.0244 15.4497 18.0363 14.6823 17.9047 14.2924C17.8568 14.1439 16.7973 12.2069 15.5522 9.97279C13.9959 7.17554 13.2178 5.8388 13.0382 5.65933C12.6312 5.22612 12.2122 5.04665 11.5657 5.02809C11.1767 5.01571 10.9432 5.03428 10.7517 5.10854Z" fill="#4285F4" />
    <path d="M9.33353 15.1301C9.33353 16.3263 8.36388 17.2959 7.16774 17.2959C5.97161 17.2959 5.00195 16.3263 5.00195 15.1301C5.00195 13.934 5.97161 12.9644 7.16774 12.9644C8.36388 12.9644 9.33353 13.934 9.33353 15.1301Z" fill="#34A853" />
    <path d="M13.6636 7.18923C13.6636 8.38536 12.694 9.35502 11.4978 9.35502C10.3017 9.35502 9.33203 8.38536 9.33203 7.18923C9.33203 5.9931 10.3017 5.02344 11.4978 5.02344C12.694 5.02344 13.6636 5.9931 13.6636 7.18923Z" fill="#4285F4" />
    <path d="M17.9996 15.1301C17.9996 16.3263 17.0299 17.2959 15.8338 17.2959C14.6376 17.2959 13.668 16.3263 13.668 15.1301C13.668 13.934 14.6376 12.9644 15.8338 12.9644C17.0299 12.9644 17.9996 13.934 17.9996 15.1301Z" fill="#4285F4" />
    <path d="M40.7331 8.06787C40.5832 7.51099 40.1441 7.07192 39.5873 6.92199C38.5699 6.64355 34.5004 6.64355 34.5004 6.64355C34.5004 6.64355 30.431 6.64355 29.4136 6.91128C28.8674 7.06121 28.4177 7.51099 28.2677 8.06787C28 9.08523 28 11.1949 28 11.1949C28 11.1949 28 13.3153 28.2677 14.322C28.4177 14.8789 28.8567 15.3179 29.4136 15.4679C30.4417 15.7463 34.5004 15.7463 34.5004 15.7463C34.5004 15.7463 38.5699 15.7463 39.5873 15.4786C40.1441 15.3286 40.5832 14.8896 40.7331 14.3327C41.0009 13.3153 41.0009 11.2056 41.0009 11.2056C41.0009 11.2056 41.0116 9.08523 40.7331 8.06787Z" fill="#FF0000" />
    <path d="M33.2051 9.24609V13.1442L36.5892 11.1952L33.2051 9.24609Z" fill="white" />
    <path d="M60.2646 9.78732C61.1059 10.3676 62.1365 10.709 63.2496 10.709V8.64226C63.039 8.6423 62.8288 8.62111 62.6227 8.57898V10.2058C61.5097 10.2058 60.4793 9.86437 59.6378 9.28416V13.5017C59.6378 15.6116 57.8651 17.3218 55.6786 17.3218C54.8628 17.3218 54.1045 17.0839 53.4746 16.6757C54.1935 17.385 55.1962 17.825 56.3054 17.825C58.492 17.825 60.2647 16.1148 60.2647 14.0048V9.78732H60.2646ZM61.0379 7.70225C60.608 7.24903 60.3257 6.66334 60.2646 6.01582V5.75H59.6706C59.8201 6.57299 60.3301 7.2761 61.0379 7.70225ZM54.8576 15.0567C54.6174 14.7528 54.4876 14.381 54.4881 13.9988C54.4881 13.0338 55.2989 12.2514 56.2992 12.2514C56.4856 12.2514 56.6709 12.2789 56.8486 12.3333V10.2204C56.641 10.1929 56.4314 10.1813 56.222 10.1855V11.8301C56.0442 11.7757 55.8588 11.7481 55.6723 11.7483C54.672 11.7483 53.8613 12.5306 53.8613 13.4957C53.8613 14.1781 54.2666 14.7689 54.8576 15.0567Z" fill="#FF004F" />
    <path d="M59.6679 9.26216C60.5154 9.83877 61.5532 10.1781 62.6742 10.1781V8.56136C62.0485 8.43446 61.4946 8.12314 61.0781 7.69012C60.3652 7.26657 59.8515 6.56783 59.7009 5.75H58.1294V13.9535C58.1258 14.9098 57.3107 15.6842 56.3054 15.6842C55.713 15.6842 55.1867 15.4153 54.8534 14.9989C54.2582 14.7129 53.8501 14.1257 53.8501 13.4476C53.8501 12.4886 54.6666 11.7111 55.674 11.7111C55.8671 11.7111 56.0531 11.7397 56.2276 11.7924V10.158C54.0642 10.2006 52.3242 11.8836 52.3242 13.9535C52.3242 14.9868 52.7575 15.9235 53.4607 16.6079C54.0951 17.0135 54.8588 17.25 55.6805 17.25C57.8827 17.25 59.6679 15.5503 59.6679 13.4536V9.26216H59.6679Z" fill="black" />
    <path d="M62.675 8.52762V8.085C62.1136 8.08582 61.5633 7.93349 61.087 7.64542C61.5087 8.09269 62.0638 8.40109 62.675 8.52762ZM59.717 5.68106C59.7027 5.60191 59.6917 5.52225 59.6841 5.44229V5.1748H57.5253V13.4811C57.5219 14.4493 56.7109 15.2333 55.7107 15.2333C55.4171 15.2333 55.1398 15.1658 54.8943 15.0458C55.2259 15.4674 55.7495 15.7396 56.3388 15.7396C57.3388 15.7396 58.15 14.9556 58.1535 13.9873V5.68106H59.717ZM56.2615 10.1443V9.67308C56.0811 9.64919 55.8993 9.6372 55.7172 9.63728C53.5261 9.63724 51.75 11.3582 51.75 13.4811C51.75 14.8119 52.4481 15.9849 53.5088 16.6748C52.8092 15.9819 52.3781 15.0334 52.3781 13.9872C52.3781 11.8915 54.1091 10.1874 56.2615 10.1443Z" fill="#00F2EA" />
  </svg>

);
