import React from 'react';

export const Yavk = () => (
  <svg width="46" height="23" viewBox="0 0 46 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M34.5 23C40.8513 23 46 17.8513 46 11.5C46 5.14873 40.8513 0 34.5 0C28.1487 0 23 5.14873 23 11.5C23 17.8513 28.1487 23 34.5 23ZM11.5 0.000488281C15.6459 0.000488281 19.2793 2.19435 21.3028 5.48448C20.4662 7.31694 20 9.35403 20 11.5C20 13.6462 20.4663 15.6835 21.3031 17.5161C19.2796 20.8064 15.646 23.0005 11.5 23.0005C5.14873 23.0005 0 17.8518 0 11.5005C0 5.14921 5.14873 0.000488281 11.5 0.000488281Z" fill="#EBEFFE" />
    <g clipPath="url(#clip0_5430_3969)">
      <rect x="5" y="5.00049" width="13" height="13" rx="6.5" fill="#1A4A7F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7924 5.7344C19.6526 6.01108 20.1166 6.92977 19.8288 7.78634L16.727 17.0182C16.4392 17.8747 15.5086 18.3448 14.6483 18.0682C13.7881 17.7915 13.3241 16.8728 13.6119 16.0162L14.6498 12.9272L0.229271 26.1614L-2.06547 23.6609L12.3453 10.4357L9.20624 11.1985C8.32817 11.4119 7.4526 10.8709 7.2506 9.99014C7.04861 9.10938 7.59667 8.22239 8.47474 8.009L17.6736 5.77348C18.0193 5.63564 18.4127 5.61229 18.7924 5.7344Z" fill="url(#paint0_linear_5430_3969)" />
    </g>
    <g clipPath="url(#clip1_5430_3969)">
      <path d="M34.2364 18.002H34.7778C37.7169 18.002 39.1867 18.002 40.0995 17.0892C41.0122 16.1765 41.0044 14.7072 41.0044 11.7754V11.2261C41.0044 8.29486 41.0044 6.82506 40.0995 5.91229C39.1945 4.99951 37.7169 4.99951 34.7778 4.99951H34.2364C31.2968 4.99951 29.8275 4.99951 28.9147 5.91229C28.002 6.82506 28.002 8.29382 28.002 11.2261V11.7754C28.002 14.7072 28.002 16.1765 28.9147 17.0892C29.8275 18.002 31.2968 18.002 34.2364 18.002Z" fill="#0077FF" />
      <path d="M34.9795 14.3742C32.0477 14.3742 30.2684 12.3401 30.1992 8.95996H31.6841C31.7304 11.4429 32.86 12.4966 33.726 12.7114V8.95996H35.1495V11.1023C35.9848 11.0097 36.8586 10.035 37.153 8.95996H38.5525C38.3284 10.2826 37.3771 11.2572 36.7041 11.6593C37.3771 11.9843 38.46 12.8352 38.8776 14.3742H37.3386C37.0136 13.3454 36.2168 12.5487 35.1495 12.4405V14.3742H34.9795Z" fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_5430_3969" x1="38.2185" y1="21.2637" x2="-0.803223" y2="25.8535" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFB800" />
        <stop offset="1" stopColor="#FFF11D" />
      </linearGradient>
      <clipPath id="clip0_5430_3969">
        <rect x="5" y="5.00049" width="13" height="13" rx="6.5" fill="white" />
      </clipPath>
      <clipPath id="clip1_5430_3969">
        <rect width="13.0025" height="13.0025" fill="white" transform="translate(28 4.99902)" />
      </clipPath>
    </defs>
  </svg>

);
