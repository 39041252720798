import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SOCIAL_ERROR,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  REGISTER_SOCIAL_ERROR,
  REGISTER_GOOGLE,
  REGISTER_YANDEX,
  REGISTER_AGENCY_REG,
  REGISTER_HAVE_ACCOUNT,
  LOGIN_GOOGLE,
  LOGIN_YANDEX,
  LOGIN_FORGOT_PASSWORD,
  RESTORE_CLICK_LOGIN,
  RESTORE_CLICK_SIGNUP,
  RESTORE_SUCCESS,
  RESTORE_NOT_RECEIVED,
} from '@/constants/analytics';
import {
  authError,
  authSuccess,
  authSocialError,
  loginGoogle,
  loginYandex,
  loginForgotPassword,
  registerError,
  registerSuccess,
  registerSocialError,
  registerGoogle,
  registerYandex,
  registerAgencyReg,
  registerHaveAccount,
  restoreClickLogin,
  restoreClickSignup,
  restoreSuccess,
  restoreNotReceived,
} from './actions';

export function reducers() {
  return {
    [AUTH_ERROR]: authError,
    [AUTH_SUCCESS]: authSuccess,
    [AUTH_SOCIAL_ERROR]: authSocialError,
    [REGISTER_ERROR]: registerError,
    [REGISTER_SUCCESS]: registerSuccess,
    [REGISTER_SOCIAL_ERROR]: registerSocialError,
    [REGISTER_GOOGLE]: registerGoogle,
    [REGISTER_YANDEX]: registerYandex,
    [LOGIN_GOOGLE]: loginGoogle,
    [LOGIN_YANDEX]: loginYandex,
    [LOGIN_FORGOT_PASSWORD]: loginForgotPassword,
    [REGISTER_AGENCY_REG]: registerAgencyReg,
    [REGISTER_HAVE_ACCOUNT]: registerHaveAccount,
    [RESTORE_CLICK_LOGIN]: restoreClickLogin,
    [RESTORE_CLICK_SIGNUP]: restoreClickSignup,
    [RESTORE_SUCCESS]: restoreSuccess,
    [RESTORE_NOT_RECEIVED]: restoreNotReceived,
  };
}
