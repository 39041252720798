import React from 'react';
import { LoaderSVG } from '@/assets/svg-inline';
import { Loader, LoaderCircular } from './loader.styled';
import { PropsTypes } from './types';

const LoaderElement = ({ fullPage }: PropsTypes) => (
  <Loader fullPage={fullPage}>
    <LoaderCircular>
      <LoaderSVG />
    </LoaderCircular>
  </Loader>
);

export default LoaderElement;
