import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '@/app/store';
import { authUser } from '@/api/auth-user';
import { captchaStatus } from '@/api/captcha-status';
import { createFormData } from '@/utils/create-form-data';
import { HTTP_STATUS_OK } from '@/constants/api';
import { RootSignInState, ValuesTypes } from './types';
import { initialState } from './state';
import {
  addValues,
  addErrors,
  startAuth,
  successAuth,
  errorAuth,
  stateClear,
  captchaSuccess,
  captchaError,
} from './actions';

const signInSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    getValues: addValues,
    getErrors: addErrors,
    authUserStart: startAuth,
    authUserSuccess: successAuth,
    authUserError: errorAuth,
    clearState: stateClear,
    successCaptcha: captchaSuccess,
    errorCaptcha: captchaError,
  },
});

export const {
  getValues,
  getErrors,
  authUserStart,
  authUserSuccess,
  authUserError,
  clearState,
  successCaptcha,
  errorCaptcha,
} = signInSlice.actions;

export const fetchSignIn = (
  values: ValuesTypes,
  fullUrlParams: string,
  recaptchaToken: string,
): AppThunk => async (dispatch: AppDispatch) => {
  const formData = createFormData({ values, fullUrlParams, recaptchaToken });

  dispatch(authUserStart());

  try {
    const success = await authUser(formData);

    if (success.status !== HTTP_STATUS_OK) throw new Error('');

    dispatch(authUserSuccess(JSON.parse(success.request.response)));
  } catch (err) {
    if (err?.response?.data?.code && err.response.data.code === 'login_locale_forbidden') {
      if (/ KAZ /.test(err.response.data.hint)) {
        window.location.href = 'https://elama.kz';
      } else {
        window.location.href = 'https://elama.global';
      }
    }

    dispatch(authUserError(err?.response?.data?.code));
  }
};

export const fetchCaptcha = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const success = await captchaStatus();

    dispatch(successCaptcha(JSON.parse(success.request.response)));
  } catch (err) {
    dispatch(errorCaptcha());
  }
};

export const signInName = signInSlice.name;

export const selectValues = (state: RootSignInState) => state.signin.values;
export const selectErrors = (state: RootSignInState) => state.signin.errors;
export const selectLoadFetch = (state: RootSignInState) => state.signin.loadFetch;
export const selectFormError = (state: RootSignInState) => state.signin.formError;
export const selectRedirectURL = (state: RootSignInState) => state.signin.redirectURL;
export const selectIsCaptcha = (state: RootSignInState) => state.signin.isCaptcha;

export default signInSlice.reducer;
