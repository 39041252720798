import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonEyeElement } from '@/elements/buttons';
import { Page } from '@/types/page';
import { useSocialError } from '@/hooks/useSocialError';
import { selectFormError } from '@/features/signup';
import { useSelector } from 'react-redux';
import ErrorMessageElement from '../error-message';
import {
  Input,
  InputControl,
  InputLabel,
  InputEye,
  InputLabelText,
} from './input.styled';
import { PropsTypes } from './types';

const InputElement = React.forwardRef<HTMLInputElement, PropsTypes>(({
  id,
  name,
  type,
  value,
  error,
  autoFocus,
  labelText,
  onChange,
  onBlur,
  ...other
}: PropsTypes, ref) => {
  const [inputType, setInputType] = useState(type);
  const [socialError] = useSocialError(Page.signup);
  const formError = useSelector(selectFormError);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({ name, value: event.target.value });
  };
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    onBlur(name);
  };

  const handleClick = useCallback((isShow: boolean) => {
    setInputType(isShow ? 'text' : 'password');
  }, []);

  return (
    <InputLabel htmlFor={id}>
      <InputLabelText>
        <FormattedMessage id={labelText} />
      </InputLabelText>
      <Input>
        <InputControl
          id={id}
          name={name}
          type={inputType}
          value={value}
          error={error || socialError || formError}
          autoFocus={autoFocus}
          placeholder="elama@elama.ru"
          onChange={handleChange}
          onBlur={handleBlur}
          ref={ref}
          {...other}
        />
        <InputEye isShow={type === 'password'}>
          <ButtonEyeElement onClick={handleClick} type={type} />
        </InputEye>
        <ErrorMessageElement error={error || socialError || formError} />
      </Input>
    </InputLabel>
  );
});

export default InputElement;
