import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorMessageElement from '@/elements/error-message';
import { ButtonEyeElement } from '@/elements/buttons';
import {
  Input,
  InputControl,
  InputLabel,
  InputEye,
} from './input.styled';
import { PropsTypes } from './types';

const InputElement = React.forwardRef<HTMLInputElement, PropsTypes>(({
  id,
  name,
  type,
  value,
  error,
  autoFocus,
  labelText,
  onChange,
  onBlur,
  ...other
}: PropsTypes, ref) => {
  const [inputType, setInputType] = useState(type);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({ name, value: event.target.value });
  };
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = () => {
    onBlur(name);
  };

  const handleClick = useCallback((isShow: boolean) => {
    setInputType(isShow ? 'text' : 'password');
  }, []);

  return (
    <Input>
      <InputControl
        id={id}
        name={name}
        type={inputType}
        value={value}
        error={error}
        autoFocus={autoFocus}
        placeholder="text"
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        {...other}
      />
      <InputLabel htmlFor={id}>
        <FormattedMessage id={labelText} />
      </InputLabel>
      <InputEye isShow={type === 'password'}>
        <ButtonEyeElement onClick={handleClick} type={type} />
      </InputEye>
      <ErrorMessageElement error={error} />
    </Input>
  );
});

export default InputElement;
