import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from '@/assets/styles/variables';
import HeaderElement from '@/elements/header';
import {
  ErrorBoundary,
  ErrorBoundaryGrid,
  ErrorBoundaryH2,
  ErrorBoundaryP,
} from './error-boundary.styled';
import { PropsTypes, StateTypes } from './types';

class ErrorBoundaryElement extends PureComponent<PropsTypes, StateTypes> {
  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <ErrorBoundary>
          <Container>
            <ErrorBoundaryGrid>
              <HeaderElement rightComponent={<></>} />
              <div>
                <ErrorBoundaryH2>
                  <FormattedMessage id="ErrorPage.title" />
                </ErrorBoundaryH2>
                <ErrorBoundaryP>
                  <FormattedMessage id="ErrorPage.text" />
                </ErrorBoundaryP>
              </div>
            </ErrorBoundaryGrid>
          </Container>
        </ErrorBoundary>
      );
    }

    return children;
  }
}

export default ErrorBoundaryElement;
