import React, { useState } from 'react';
import { getIcon } from '@/utils/get-icon';
import { ButtonEye } from './button-eye.styled';
import { PropsTypes } from './types';

function ButtonEyeElement({ onClick }: PropsTypes) {
  const [isShow, setShow] = useState(false);

  const handleClick = () => {
    setShow((prevState) => !prevState);
    onClick(!isShow);
  };

  return (
    <ButtonEye type="button" onClick={handleClick}>
      {isShow ? getIcon('eye-show') : getIcon('eye-hide')}
    </ButtonEye>
  );
}

export default ButtonEyeElement;
