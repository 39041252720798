import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTranslations,
  selectTranslations,
  selectLang,
} from '@/features/global';

export const useTranslations = () => {
  const translations = useSelector(selectTranslations);
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);

  useEffect(() => {
    if (translations !== null) return;

    dispatch(fetchTranslations(lang));
  }, [translations, dispatch, lang]);

  return translations;
};
