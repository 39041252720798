import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Line, LineText } from './line.styled';
import type { Props } from './types';

function LineElement({ text = 'Social.content.text' }: Props) {
  return (
    <Line>
      <LineText>
        <FormattedMessage id={text} />
      </LineText>
    </Line>
  );
}

export default LineElement;
