import * as LANG from '@/constants/language';
import { LocaleTypes } from '@/types/locale';

export const mapLang = (locale: LocaleTypes) => {
  switch (locale) {
    case 'ru':
    case 'ru_KZ':
      return LANG.RUS_LANG;
    case 'pt_BR':
      return LANG.POR_LANG;
    default:
      return LANG.ENG_LANG;
  }
};
