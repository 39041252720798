import styled, { css } from 'styled-components';

export const Agreement = styled.label`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  width: auto;
  white-space: initial;
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.two};
    font-family: ${theme.typography.fontFamily.navigo};
  `};
`;

export const AgreementIndicator = styled.span`
  position: relative;
  padding-left: 30px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid;
    border-radius: 4px;
    transition: all .2s linear;
  }
  ${({ error }: {error?: string}) => (error
    ? css`
      &:before {
        border: 2px solid #FF3333 !important;
      }
    `
    : css`
    &:before {
      border: 1px solid #E0E3EB;
    }
  `)
};
`;

export const AgreementInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  z-index: -1;
  &:hover ~ ${AgreementIndicator} {
    &:before {
      border-color: #C2C7D6;
    }
  }
  &:checked ~ ${AgreementIndicator} {
    &:hover {
      &:before {
        border-color: #E0E3EB;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEPSURBVHgBrdS/asJQFIDx7960CKWFQKYOBafOFt+hUwN9hHYpuLX0QTq2nVrfQNEHcHITnZ0EXQVnQa/n+AdCVEzi/ZaEQH7ck1yuQXr5duXLgD8cFQMh+WvMl3z818zIrDFLvyCUbCbogy0FfHnAtFCntM7xjKeMfDKL30LfIIXA6BoqZU+gYp8x1B7hLjoT3GHRDXSHMJ5mBOPq/khprN7hYBfpBzrGU3Vzry/py1kxzbz9yk5MpWC8RQcjuL+Fq9Jp7OAKtXZvc02OngXTjv4URVu9fNjRFSZRHXkyJXMnt00ebA3KH5nhMWsNAzwl+6VpzYJXH6tUI3C82x85tgM5aeVJgwIpJCvrqKHWCmu/WxWcEH1TAAAAAElFTkSuQmCC");
      }
    }
    &:before {
      background-size: 100%;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEwSURBVHgBrdU/S8NAGMfx3/O0xf8S6tYgOIgYUNBBfAMFV30JdRSFdvRVKGhnBwc3qaPg4O6g4BDduiguQhAHDfQe7ykt+CfaJN53CTnIh7tcuBBs8/VwpkOFI4Is2VsPWSO0YmMa7f2gTYoZ4utc0NeiWMwyG+Y9B5jmlewqGYJ1OEpfGcNtnmsQuUC/XEJ1cdwNqNjx1jSatQoCf+h/YB/zy0WcXr0gfHhPB+6sTaG6MPYntnvylPToT1CXsW3B5qaPjZXJTJhGc417+T6ooM5Su7h9xersKCZGeCCmFZMGD8+fu9fu0nu7mQbTft0URQ96cFpMS1zy54LKMMLHN6Rt4GeTBeuDERzGInQDV4mccQGdGtzMMoohdb6zxzbbk9aeiy3kKxLQpZ7W+gv4AGXjcMVHWG3CAAAAAElFTkSuQmCC");
    }
  }
`;
