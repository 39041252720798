import { getHashes, getStateUrl } from '@/utils/get-state';
import { InitialStateTypes } from './types';

const { locale, fullUrlParams, lang } = getStateUrl();

export const initialState: InitialStateTypes = {
  locale,
  lang,
  fullUrlParams,
  hashes: getHashes(),
  translations: null,
  isLoading: false,
};
