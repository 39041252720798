export const KZ_AREA_CODE_LIST = [
  '705',
  '727',
  '700',
  '708',
  '705',
  '771',
  '776',
  '777',
  '701',
  '702',
  '775',
  '778',
  '707',
  '747',
];
