import { getParamsObject } from '@/utils/get-params-object';
import { UPDATE_PASSWORD_PARAMS } from '@/constants/params';
import { InitialStateTypes } from './types';

const { login, confirmationCode } = getParamsObject(UPDATE_PASSWORD_PARAMS);

export const initialState: InitialStateTypes = {
  values: {
    newPassword: '',
    login,
    confirmationCode,
  },
  errors: { newPassword: '' },
  isSubmitting: false,
  loadFetch: false,
  isErrorUpdate: false,
  isDisableButton: true,
  formError: '',
};
