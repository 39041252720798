import styled, { css } from 'styled-components';
import { StylesTypes } from './types';

export const Social = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 30px;
  ${({ theme, ruLang }: StylesTypes) => css`
    grid-template-rows: ${ruLang ? 'repeat(3, 50px)' : 'repeat(2, 50px)'};
    font-size: ${theme.typography.fontSize.one};
    line-height: ${theme.typography.lineHeight.one};
    font-family: ${theme.typography.fontFamily.navigo};
    @media(max-width: ${theme.breakpoints.mobile}px) {
      margin-bottom: 20px;
    }
    @media(min-width: ${theme.breakpoints.mobile}px) {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  `};
`;
