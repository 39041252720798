import { keyframes } from 'styled-components';

const loaderRotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const loaderDash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

const loaderFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const animations = {
  loaderRotate,
  loaderDash,
  loaderFadeIn,
};
