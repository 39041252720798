import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCaptcha,
  fetchSignIn, getErrors,
  selectIsCaptcha,
  selectValues,
} from '@/features/signin';
import { selectFullUrlParams, selectLang } from '@/features/global';
import { createValidate } from '@/services/validate';
import { PropsTypes, ReturnTypes } from './types';

const { validate } = createValidate();

export function useSignIn(recaptchaRef: PropsTypes): ReturnTypes {
  const values = useSelector(selectValues);
  const fullUrlParams = useSelector(selectFullUrlParams);
  const lang = useSelector(selectLang);
  const isCaptcha = useSelector(selectIsCaptcha);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCaptcha());
  }, [dispatch]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (!validate.signin(values)) {
      dispatch(getErrors('email'));
      dispatch(getErrors('password'));

      return;
    }

    const recaptchaToken = isCaptcha ? await recaptchaRef?.current?.executeAsync() as string : '';
    if (isCaptcha) recaptchaRef?.current?.reset();

    dispatch(fetchSignIn(values, fullUrlParams, recaptchaToken));
  }, [dispatch, fullUrlParams, isCaptcha, recaptchaRef, values]);

  return [lang, handleSubmit];
}
