import styled, { css } from 'styled-components';

export const LinkDom = styled.a`
  width: max-content;
  &.footer-img {
    display: flex;
    align-items: center;
    &:hover img {
      transform: translateX(5px);
    }
    img {
      transition: all linear .2s;
      margin-left: 10px;
    }
  }
  &:focus {
    outline: none;
  }
  ${({ theme }) => css`
    color: ${theme.colors.blue.three};

    &:hover{
      color: ${theme.colors.blue.four};
    }
    &:active {
      color: ${theme.colors.blue.four};
    }
  `};
`;
