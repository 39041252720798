import React from 'react';

export function EyeHide() {
  return (
    <svg width="17" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.504 3.158c2.134 0 3.865 1.768 3.865 3.947 0 .513-.1.995-.278 1.445l2.257 2.305A9.324 9.324 0 0 0 17 7.105c-1.337-3.466-4.639-5.92-8.504-5.92a8.836 8.836 0 0 0-3.077.552l1.67 1.705c.44-.181.912-.284 1.415-.284zM.774 1.003l1.762 1.8.355.363A9.304 9.304 0 0 0 0 7.106c1.337 3.465 4.638 5.92 8.504 5.92a8.938 8.938 0 0 0 3.386-.663l.325.332L14.48 15l.982-1.003L1.755 0 .773 1.003zm4.274 4.365l1.198 1.224c-.038.166-.061.34-.061.513 0 1.31 1.036 2.369 2.319 2.369.17 0 .34-.024.502-.063l1.199 1.223c-.518.26-1.09.419-1.701.419-2.134 0-3.866-1.769-3.866-3.948 0-.623.155-1.208.41-1.737zm3.332-.615l2.435 2.486.016-.126c0-1.31-1.036-2.368-2.32-2.368l-.13.008z"
        fill="#C6CAD8"
      />
    </svg>
  );
}
