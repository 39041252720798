import { ajax } from '@/utils/ajax';

/**
 * для совместимости с предыдущей версией формы и чтобы не ломать метрики аналитиков,
 * шлём в /client/log сервиса identity
 * @param userId
 */
export const pushAnalytics = async (userId: string) => {
  const url = `https://${window.location.hostname}/client/log`;

  let clientId;
  let previousGoogleAnalyticsClientId;

  try {
    if (!window.ga) {
      return;
    }
    clientId = window.ga.getAll()[0].get('clientId');
    previousGoogleAnalyticsClientId = sessionStorage.getItem(`googleAnalyticsClientId${userId}`);
  } catch (e) {
    console.warn(e);
    return;
  }

  if (previousGoogleAnalyticsClientId === clientId) {
    return;
  }

  const params = { userId, googleAnalyticsClientId: clientId, t: new Date().getTime() };

  const getUriString = (obj: {[key: string]: string | number}) => (Object
    .keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&'));

  const response = await ajax.get(`${url}?${getUriString(params)}`, {
    timeout: 1000,
  }).catch((res) => res);

  if (response.ok) {
    sessionStorage.setItem(`googleAnalyticsClientId${userId}`, clientId);
  }
};
