import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from './title.styled';
import { PropsTypes } from './types';

function TitleElement({
  text,
  values,
}: PropsTypes) {
  return (
    <>
      <Title>
        <FormattedMessage id={text} values={values} />
      </Title>
    </>
  );
}

export default TitleElement;
