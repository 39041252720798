import styled, { css } from 'styled-components';

export const RightComponent = styled.span`
  margin-right: 20px;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.navigo};
    @media(max-width: ${theme.breakpoints.mobile}px) {
      display: none;
    }
  `};
`;
