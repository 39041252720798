import React from 'react';
import { FormikProps } from 'formik';
import { HelpBubble, InputPassword, Button } from '@elamajs/ui-kit';
import { FIELD } from '@/schemas/update';
import { isMobileScreen } from '@/utils/media';
import PasswordHint from '../PasswordHint';
import Field from '../Field/Field';

import * as Styled from './UpdateForm.styled';

type Props = FormikProps<FormData> & {
  isLoading: boolean
};

const UpdateForm = ({
  values,
  setFieldValue,
  handleSubmit,
  isLoading,
  isValid,
  dirty,
  errors,
}: Props) => {
  const handleChange = (
    value: string | boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue(event.target.name, value);
  };

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Field text="Пароль">
        <HelpBubble directions={isMobileScreen() ? ['bottom'] : ['right']} content={<PasswordHint value={values[FIELD.PASSWORD]} />}>
          <InputPassword
            name={FIELD.PASSWORD}
            width="100%"
            placeholder="От 8 символов"
            value={values[FIELD.PASSWORD]}
            onChange={handleChange}
            errorMessage={errors[FIELD.PASSWORD]}
          />
        </HelpBubble>
      </Field>
      <Button loading={isLoading} disabled={!(isValid && dirty)} type="submit">Сохранить пароль</Button>
    </Styled.Form>
  );
};

export default UpdateForm;
