import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '@/app/store';
import { getTranslations } from '@/api/translations';
import { LangTypes } from '@/types/lang';
import { initialState } from './state';
import { loadingFailed, loadingSuccess } from './actions';
import { RootGlobalState } from './types';

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getTranslationsSuccess: loadingSuccess,
    getTranslationsFailed: loadingFailed,
  },
});

export const { getTranslationsSuccess, getTranslationsFailed } = globalSlice.actions;

export const fetchTranslations = (lang: LangTypes): AppThunk => (
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await getTranslations(lang);
      dispatch(getTranslationsSuccess(data));
    } catch (err) {
      dispatch(getTranslationsFailed());
    }
  });

export const globalName = globalSlice.name;

export const selectFullUrlParams = (state: RootGlobalState) => state.global.fullUrlParams;
export const selectHashes = (state: RootGlobalState) => state.global.hashes;
export const selectLocale = (state: RootGlobalState) => state.global.locale;
export const selectLang = (state: RootGlobalState) => state.global.lang;
export const selectTranslations = (state: RootGlobalState) => state.global.translations;
export const selectIsLoading = (state: RootGlobalState) => state.global.isLoading;

export default globalSlice.reducer;
