import React from 'react';
import { getIcon } from '@/utils/get-icon';
import { SOCIAL_URLS } from '@/constants/urls';
import { LinkSocial, LinkSocialContent, LinkSocialText } from './link-social.styled';
import { PropsTypes } from './types';

function LinkSocialElement({
  text,
  iconName,
  fullUrlParams,
  ruLang,
  onClick,
}: PropsTypes) {
  if (!ruLang && iconName === 'yandex') return <></>;

  return (
    <LinkSocial
      href={SOCIAL_URLS[iconName] + fullUrlParams}
      onClick={onClick}
      data-social-name={iconName}
    >
      <LinkSocialContent>
        {getIcon(iconName)}
        <LinkSocialText>{text}</LinkSocialText>
      </LinkSocialContent>
    </LinkSocial>
  );
}

export default LinkSocialElement;
