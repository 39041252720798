import { SocialName } from '@/elements/social/types';
import { ConfigTypes, MapsSocialName } from '@/types/analytics';
import { Page } from '@/types/page';

export const EVENT_CATEGORIES = {
  signUp: 'Form Registration',
  login: 'Form Log in',
  restore: 'Form Restore password',
};

export const EVENT_ACTIONS = {
  signUpChoiceGoogle: 'Step 1 - Google',
  signUpChoiceYandex: 'Step 1 - Yandex',
  signUpHaveAccount: 'Step 1 - Have an account - Log In',
  signUpChoiceCountry: 'Step 1 - Change country',
  signUpSuccessReg: 'Step 1 - Sign Up button',
  signUpClickedSubmitFormOneStep: 'Step 1 - Sign Up button',
  signUpError: 'Error registration',
  signUpSocialError: 'Error registration via social',
  signUpAgencyReg: 'Agency_reg',
  loginChoiceGoogle: 'Google',
  loginChoiceYandex: 'Yandex',
  loginClickedSubmitForm: 'Email',
  loginWrongEmailPassword: 'Wrong email or password',
  loginForgotPassword: 'Forgot password',
  loginSocialError: 'Error login via social',
  restoreSuccess: 'Send form',
  restoreClickLogin: 'Log In button',
  restoreClickSignup: 'Registration button',
  restoreNotReceived: 'Letter not received',
};

export const AUTH_ERROR = '[AUTH_ERROR]';
export const AUTH_SUCCESS = '[AUTH_SUCCESS]';
export const AUTH_SOCIAL_ERROR = '[AUTH_SOCIAL_ERROR]';
export const LOGIN_GOOGLE = '[LOGIN_GOOGLE]';
export const LOGIN_YANDEX = '[LOGIN_YANDEX]';
export const LOGIN_FORGOT_PASSWORD = '[LOGIN_FORGOT_PASSWORD]';
export const REGISTER_ERROR = '[REGISTER_ERROR]';
export const REGISTER_SUCCESS = '[REGISTER_SUCCESS]';
export const REGISTER_GOOGLE = '[REGISTER_GOOGLE]';
export const REGISTER_YANDEX = '[REGISTER_YANDEX]';
export const REGISTER_SOCIAL_ERROR = '[AUTH_SOCIAL_ERROR]';
export const REGISTER_AGENCY_REG = '[REGISTER_AGENCY_REG]';
export const REGISTER_HAVE_ACCOUNT = '[REGISTER_HAVE_ACCOUNT]';
export const RESTORE_CLICK_LOGIN = '[RESTORE_CLICK_LOGIN]';
export const RESTORE_CLICK_SIGNUP = '[RESTORE_CLICK_SIGNUP]';
export const RESTORE_SUCCESS = '[RESTORE_SUCCESS]';
export const RESTORE_NOT_RECEIVED = '[RESTORE_NOT_RECEIVED]';

export const CONFIG: ConfigTypes = {
  event: 'universal_event',
  event_label: '(not set)',
  universal_event_systems: 'google_analytics',
};

export const MAPS_SOCIAL_NAME: MapsSocialName = {
  [Page.signin]: {
    [SocialName.google]: LOGIN_GOOGLE,
    [SocialName.yandex]: LOGIN_YANDEX,
  },
  [Page.signup]: {
    [SocialName.google]: REGISTER_GOOGLE,
    [SocialName.yandex]: REGISTER_YANDEX,
  },
};
