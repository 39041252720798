import React, { useCallback } from 'react';
import { createAnalytics } from '@/services/analytics';
import { ROUTE_RESTORE_PASSWORD } from '@/constants/routes';
import { LOGIN_FORGOT_PASSWORD } from '@/constants/analytics';
import { LinkElement } from '@/elements/links';

const { sendAnalytics } = createAnalytics();

function SignInForgotPassword() {
  const handleLinkClick = useCallback(() => {
    sendAnalytics(LOGIN_FORGOT_PASSWORD);
  }, []);

  return <LinkElement href={ROUTE_RESTORE_PASSWORD} text="SignInForm.forgotPassword" onClick={handleLinkClick} />;
}

export default SignInForgotPassword;
