import styled, { css, keyframes } from 'styled-components';
import { PropsTypes } from './types';

const loaderFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Loader = styled.div<PropsTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  ${({ fullPage }) => fullPage && css`
    height: 100vh;
  `};
`;

export const LoaderCircular = styled.div`
  display: inline-block;
  vertical-align: middle;
  transform-origin: center center;
  min-height: 0;
  width: 80px;
  height: 80px;
  border-width: 4px;
  color: #039be6;
  animation: ${loaderFadeIn} 1s 1 both;
`;
