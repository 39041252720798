import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  REACT_APP_RECAPTCHA_PUBLIC_KEY, RECAPTCHA_BADGE,
  RECAPTCHA_ENABLED,
  RECAPTCHA_SIZE,
  RECAPTCHA_THEME,
} from '@/constants/re-captcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { getStateUrl } from '@/utils/get-state';
import { Button, Attention } from '@elamajs/ui-kit';
import { ROUTE_SIGN_IN } from '@/constants/routes';
import { createFormData } from '@/utils/create-form-data';
import { restorePassword } from '@/api/restore-password';
import { HTTP_STATUS_OK } from '@/constants/api';
import { RESTORE_SUCCESS } from '@/constants/analytics';
import { sendAnalytics } from '@/services/analytics/send-analytics';
import { ERRORS } from '@/constants/errors';
import { INITIAL_VALUES, SCHEMA } from '@/schemas/restore';
import RestoreForm from '../RestoreForm';
import HeadTitle from '../HeadTitle';

import * as Styled from './RestoreContent.styled';

const RestoreContent = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [globalError, setGlobalError] = useState('');
  const [isRestoreSuccess, setIsRestoreSuccess] = useState(false);
  const { lang, fullUrlParams } = getStateUrl();
  const recaptchaRef = useRef<ReCAPTCHA>();

  const handleSignInPage = (e) => {
    e.preventDefault();
    history.push(ROUTE_SIGN_IN);
  };

  const handleRestore = () => {
    setError('');
    setGlobalError('');
    setIsRestoreSuccess(false);
  };

  const handleSubmit = async (values) => {
    const recaptchaToken = await recaptchaRef?.current?.executeAsync() as string;
    const { login } = values;
    // TODO логин может быть телефоном
    const data = { login: login.trim() };
    const formData = createFormData({ values: data, fullUrlParams, recaptchaToken });
    setIsLoading(true);

    try {
      const success = await restorePassword(formData);

      if (success.status !== HTTP_STATUS_OK) throw new Error('');
      sendAnalytics(RESTORE_SUCCESS);
      setIsRestoreSuccess(true);
    } catch (err) {
      const errorCode = err?.response?.data?.code;
      if (errorCode === 'invalidRequestData') {
        setError(err?.response?.data?.context.login);
      } else if (ERRORS[errorCode]) {
        setError(ERRORS[errorCode]);
      } else {
        setGlobalError(ERRORS.errorDefault);
      }
    } finally {
      recaptchaRef?.current?.reset();
      setIsLoading(false);
    }
  };

  return (
    <Styled.Body>
      {isRestoreSuccess ? (
        <>
          <HeadTitle text="Перейдите по ссылке из письма" />
          <Styled.Text>
            Мы отправили ссылку для сброса пароля на почту, которая указана в аккаунте eLama
          </Styled.Text>
        </>
      ) : <HeadTitle text="Сброс пароля" />}
      <Styled.Attention as={Attention} type="error" isOpened={globalError && !isRestoreSuccess}>
        {globalError}
      </Styled.Attention>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <RestoreForm
            {...props}
            error={error}
            isLoading={isLoading}
            isRestoreSuccess={isRestoreSuccess}
          />
        )}
      </Formik>
      {isRestoreSuccess ? (
        <Styled.Button as={Button} onClick={handleRestore} variant="outlined">
          Письмо не пришло
        </Styled.Button>
      ) : (
        <Styled.Button as={Button} onClick={(e) => handleSignInPage(e)} variant="outlined">
          Вернуться к входу
        </Styled.Button>
      )}
      {RECAPTCHA_ENABLED && (
        <ReCAPTCHA
          ref={recaptchaRef}
          hl={lang}
          size={RECAPTCHA_SIZE}
          theme={RECAPTCHA_THEME}
          sitekey={REACT_APP_RECAPTCHA_PUBLIC_KEY}
          badge={RECAPTCHA_BADGE}
        />
      )}
    </Styled.Body>
  );
};

export default RestoreContent;
