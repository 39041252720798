import { combineReducers } from '@reduxjs/toolkit';
import globalReducer, { globalName } from '@/features/global';
import signInReducer, { signInName } from '@/features/signin';
import signUpReducer, { signUpName } from '@/features/signup';
import restoreReducer, { restoreName } from '@/features/restore';
import updateReducer, { updateName } from '@/features/update';

const rootReducer = combineReducers({
  [globalName]: globalReducer,
  [signInName]: signInReducer,
  [signUpName]: signUpReducer,
  [restoreName]: restoreReducer,
  [updateName]: updateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
