import React from 'react';

export const ArrowLeft = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.448 4.73402C10.7553 4.4182 10.7553 3.91514 10.448 3.59931C10.1286 3.27101 9.60118 3.27101 9.28175 3.59931L5.67851 7.30264C5.30081 7.69084 5.30081 8.30916 5.67851 8.69735L9.28175 12.4007C9.60118 12.729 10.1286 12.729 10.448 12.4007C10.7553 12.0849 10.7553 11.5818 10.448 11.266L7.94878 8.69736C7.57108 8.30917 7.57108 7.69084 7.94878 7.30264L10.448 4.73402Z"
      fill="currentColor"
    />
  </svg>
);
