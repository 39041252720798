import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoadFetch } from '@/features/signin';
import { ButtonElement } from '@/elements/buttons';

function SignInSubmit() {
  const loadFetch = useSelector(selectLoadFetch);

  return (
    <ButtonElement text="SignInForm.submit" type="submit" disabled={loadFetch} />
  );
}

export default SignInSubmit;
