import styled, { css } from 'styled-components';

export const ErrorBoundary = styled.section`
  position: relative;
  padding: 4% 40px 30px;
  ${({ theme }) => css`
    color: ${theme.colors.black.one};
    background-color: ${theme.colors.white.one};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      padding: 80px 0 30px;
    }
  `};
`;

export const ErrorBoundaryGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  ${({ theme }) => css`
    gap: 60px;
    @media(min-width: ${theme.breakpoints.mobile}px) {
      gap: 70px;
    }
  `};
`;

export const ErrorBoundaryH2 = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 10px;
    font-size: ${theme.typography.fontSize.three};
    line-height: ${theme.typography.lineHeight.two};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      font-size: ${theme.typography.fontSize.four};
      line-height: ${theme.typography.lineHeight.five};
    }
  `};
`;

export const ErrorBoundaryP = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize.two};
    line-height: ${theme.typography.lineHeight.four};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      font-size: ${theme.typography.fontSize.three};
      line-height: ${theme.typography.lineHeight.two};
    }
  `};
`;
