export const submitErrors: {[index: string]: string} = {
  'Form.error.access_denied': 'Чтобы войти в этот аккаунт, включите VPN',
  'Form.error.criticalError': 'Попробуйте еще раз или обратитесь в Службу Заботы',
  'Form.error.emailInvalid': 'Некорректный email',
  'Form.error.forms.soc_auth.no_email_in_account': 'В этом аккаунте не указана почта. Зарегистрируйтесь с помощью другого аккаунта или введите почту и пароль вручную.',
  'Form.error.forms.soc_auth.no_such_relation_for_user': 'Вы уже регистрировались в eLama с помощью другого аккаунта Google или Яндекса. Выберите этот аккаунт или введите логин и пароль вручную.',
  'Form.error.forms.soc_auth.user_exists': 'Вы уже зарегистрированы в eLama. Войдите с помощью почты и пароля.',
  'Form.error.invalid_client': 'Неверный логин или пароль.',
  'Form.error.invalid_credentials': 'Неверный логин или пароль.',
  'Form.error.invalidRequestData': 'Некорректные данные, попробуйте снова',
  'Form.error.user_inactive': 'Мы ограничили доступ в аккаунт из-за подозрительной активности. Чтобы войти в аккаунт, позвоните нам: 8 800 500‒31‒90',
  'Form.error.userExists': 'Аккаунт с такой почтой уже есть в eLama. Измените почту или авторизуйтесь.',
  'Form.error.userRegistrationError': 'В eLama уже есть аккаунт, зарегистрированный на эту почту. Если это ваш аккаунт, попробуйте войти.',
  'Form.error.wrongCaptcha': 'Неверная reCaptcha, попробуйте снова',
  'Form.error.login_locale_forbidden': 'Сайт elama.global закрыт на техническое обслуживание',
};
