import React from 'react';
import logo from '@/assets/images/logo.svg';
import { INFOSITE_URL } from '@/constants/urls';

import * as Styled from './Header.styled';

const Header = () => (
  <Styled.HeaderLogo href={INFOSITE_URL} rel="nofollow" aria-label="link to elama site">
    <img src={logo} alt="eLama" />
  </Styled.HeaderLogo>
);

export default Header;
