import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/features/global';
import { INFOSITE_URLS } from '@/constants/urls';
import logo from '@/assets/images/logo.svg';
import { Header, HeaderRight, HeaderLogo } from './header.styled';
import { PropsTypes } from './types';

function HeaderElement({ className, rightComponent }: PropsTypes) {
  const locale = useSelector(selectLocale);

  return (
    <Header className={className}>
      <HeaderLogo href={INFOSITE_URLS[locale]} rel="nofollow" aria-label="link to elama site">
        <img src={logo} alt="eLama" />
      </HeaderLogo>
      <HeaderRight>{rightComponent}</HeaderRight>
    </Header>
  );
}

export default HeaderElement;
