import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkElement } from '@/elements/links';
import { MobileDetail } from './mobile-detail.styled';
import { PropsTypes } from './types';

function MobileDetailElement({ text, href, textLink }: PropsTypes) {
  return (
    <MobileDetail>
      <FormattedMessage id={text} />
      &nbsp;
      <LinkElement href={href} text={textLink} />
    </MobileDetail>
  );
}

export default MobileDetailElement;
