import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getValues,
  selectValues,
  selectErrors,
  getErrors,
} from '@/features/signin';
import InputElement from '@/elements/input';
import { NameTypes } from '@/types/name';
import { Fields } from './fields.styled';
import { ValuesTypes } from './types';

function SignInFields() {
  const values = useSelector(selectValues);
  const errors = useSelector(selectErrors);
  const dispatch = useDispatch();

  const onChange = useCallback(({ name, value }: ValuesTypes) => {
    dispatch(getValues({ name, value }));
  }, [dispatch]);

  const onBlur = useCallback((name: NameTypes) => {
    dispatch(getErrors(name));
  }, [dispatch]);

  return (
    <Fields>
      <InputElement
        labelText="Form.field.email"
        type="email"
        value={values.email}
        onChange={onChange}
        onBlur={onBlur}
        id="InputElement__email"
        name="email"
        error={errors.email}
        autoFocus
      />
      <InputElement
        labelText="Form.field.password"
        type="password"
        value={values.password}
        onChange={onChange}
        onBlur={onBlur}
        name="password"
        error={errors.password}
        id="InputElement__password"
      />
    </Fields>
  );
}

export default SignInFields;
