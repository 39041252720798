import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import { PhoneMaskStyled } from '@/pages/signup/elements/phone/phonemask';
import ru from 'react-phone-input-2/lang/ru.json';
import { countriesList } from '@/constants/countries-list';
import { KZ_AREA_CODE_LIST } from '@/constants/area-codes';
import ErrorMessageElement from '../error-message';
import { PropsTypes } from './types';
import {
  PhoneLabel,
  PhoneLabelText,
  PhoneLabelNote,
} from './phone.styled';

function PhoneElement({
  id,
  error,
  labelText,
  value,
  name,
  onChange,
  onBlur,
  country,
}: PropsTypes) {
  const handlePhoneChange = (phoneValue: string, phoneInfo: { countryCode: string; }) => {
    const countryShortName = phoneInfo.countryCode.toUpperCase();
    const countryCode = countriesList[countryShortName];
    onChange({ name, value: phoneValue, countryCode });
  };

  const handleBlur = () => {
    onBlur(name);
  };

  return (
    <PhoneLabel htmlFor={id} error={error}>
      <PhoneLabelText>
        <FormattedMessage id={labelText} />
      </PhoneLabelText>
      <PhoneLabelNote>
        Обещаем не слать рекламу и звонить только по делу
      </PhoneLabelNote>
      <PhoneMaskStyled />
      <PhoneInput
        localization={ru}
        enableSearch
        searchPlaceholder="Поиск"
        areaCodes={{ kz: KZ_AREA_CODE_LIST }}
        searchNotFound="Не найдено"
        country={country?.toLowerCase()}
        value={value}
        onBlur={handleBlur}
        onChange={handlePhoneChange}
      />
      <ErrorMessageElement error={error} />
    </PhoneLabel>
  );
}

export default PhoneElement;
