import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectValues,
  selectErrorsValues,
  getValues,
  getErrorsValue,
  selectValuesCountry,
} from '@/features/signup';
import { NameTypes } from '@/types/name';
import PhoneElement from '../elements/phone';
import InputElement from '../elements/input';
import { Fields } from './fields.styled';
import { ValuesTypes } from './types';

function SignUpFields() {
  const values = useSelector(selectValues);
  const errors = useSelector(selectErrorsValues);
  const country = useSelector(selectValuesCountry);
  const dispatch = useDispatch();
  const [phoneCountry, setPhoneCountry] = useState(country);
  const onChange = useCallback(({ name, value }: ValuesTypes) => {
    dispatch(getValues({ name, value }));
  }, [dispatch]);

  const onPhoneChange = useCallback(({ name, value, countryCode }: ValuesTypes) => {
    const formattedValue = `+${value}`;

    if (country !== countryCode) {
      setPhoneCountry(countryCode);
    }

    dispatch(getValues({ name, value: formattedValue }));
    dispatch(getErrorsValue(name));
  }, [dispatch, country]);

  const onBlur = useCallback((name: NameTypes) => {
    dispatch(getErrorsValue(name));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getValues({ name: 'country', value: phoneCountry! }));
  }, [dispatch, phoneCountry]);

  return (
    <Fields>
      <InputElement
        labelText="Email"
        name="email"
        type="email"
        value={values.email}
        onChange={onChange}
        onBlur={onBlur}
        id="InputElement__email"
        error={errors.email}
      />
      <PhoneElement
        error={errors.phone}
        id="InputElement__phone"
        name="phone"
        value={values.phone}
        labelText="Form.field.phone"
        onChange={onPhoneChange}
        onBlur={onBlur}
        country="RU"
      />
    </Fields>
  );
}

export default SignUpFields;
