import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    min-width: 320px;
    overflow-x: hidden;
    color: #1d1d39;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    background-color: #fff;
    font-family: "Museo-Sans-Cyrl", Arial, Helvetica, sans-serif;
  }

  svg {
    fill: currentColor;
    display: block;
  }

  a {
    background-color: transparent;
    text-decoration: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: inherit;
    cursor: pointer;
    outline: none;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  }

  button {
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background-color: transparent;
    user-select: none;
    appearance: none;
    position: relative;
    letter-spacing: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  }

  button[disabled],
  button[disabled]:focus,
  button[disabled]:hover {
    cursor: default;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #d9dde4;
  }

  input {
    background-color: transparent;
    padding: 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    appearance: none;
  }

  label {
    will-change: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
