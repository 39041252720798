import React, { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectFullUrlParams, selectHashes, selectLang } from '@/features/global';
import { createHashParam } from '@/utils/create-hash-param';
import { isRuLang } from '@/utils/is-lang';
import { createAnalytics } from '@/services/analytics';
import { MAPS_SOCIAL_NAME } from '@/constants/analytics';
import { LinkSocialElement } from '../links';
import { Social } from './social.styled';
import { SocialName, PropsTypes } from './types';

const { sendAnalytics } = createAnalytics();

function SocialElement({ namePage }: PropsTypes) {
  const fullUrlParams = useSelector(selectFullUrlParams);
  const hashes = useSelector(selectHashes);
  const lang = useSelector(selectLang);
  const hashParamsWithUrl = createHashParam(fullUrlParams, hashes);
  const ruLang = isRuLang(lang);

  const handleClick = useCallback((event: SyntheticEvent<HTMLElement>) => {
    const { socialName } = event.currentTarget.dataset;
    sendAnalytics(MAPS_SOCIAL_NAME[namePage][socialName as SocialName]);
  }, [namePage]);

  return (
    <Social ruLang={ruLang}>
      <LinkSocialElement
        iconName={SocialName.google}
        text="Google"
        fullUrlParams={hashParamsWithUrl}
        ruLang={ruLang}
        onClick={handleClick}
      />
      <LinkSocialElement
        iconName={SocialName.yandex}
        text="Яндекс"
        fullUrlParams={hashParamsWithUrl}
        ruLang={ruLang}
        onClick={handleClick}
      />
    </Social>
  );
}

export default SocialElement;
