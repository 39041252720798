export const FROM_ID = 'formId';
export const SOURCE_URL = 'sourceUrl';
export const REF = 'ref';
export const BACK_URL = 'backUrl';
export const LOGIN = 'login';
export const CONFIRMATION_CODE = 'confirmationCode';
export const SIGNUP_WITH_CODE_VERIFICATION = 'newRegistrationFlow';
export const SIGNUP_WITH_UPDATED_DESIGN = 'updatedDesign';

export const FORM_DATA_PARAMS = [FROM_ID, SOURCE_URL, REF];
export const UPDATE_PASSWORD_PARAMS = [LOGIN, CONFIRMATION_CODE];
export const SIGNUP_PARAMS = [SIGNUP_WITH_CODE_VERIFICATION, SIGNUP_WITH_UPDATED_DESIGN];
