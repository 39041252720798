import styled, { css } from 'styled-components';

export const LinkSocial = styled.a`
  ${({ theme }) => css`
    color: inherit;
    border-radius: ${theme.border.radius.one};
    border: 1px solid ${theme.colors.gray.one};
    font-size: ${theme.typography.fontSize.two};
    line-height: ${theme.typography.lineHeight.six};
    background-color: #fff;
    height: 50px;
    transition: background-color .2s linear;
    &:hover {
      background-color: #D4E3F7;
    }
  `};
`;

export const LinkSocialContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LinkSocialText = styled.span`
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
