import styled, { css } from 'styled-components';
import { StylesTypes } from './types';

export const Input = styled.div`
  position: relative;
`;

export const InputLabel = styled.label`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: text;
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.one};
    color: ${theme.colors.black.one};
    transition: transform ${theme.transitions.duration['200']} ${theme.transitions.easing.easeInOut};
    will-change: auto;
  `};
`;

export const InputControl = styled.input`
  width: 100%;
  height: 60px;
  padding: 0 20px;
  &::placeholder {
    color: transparent;
    font-size: 0;
  }
  ${({ theme, error }: StylesTypes) => css`
    font-size: ${theme.typography.fontSize.three};
    line-height: ${theme.typography.lineHeight.two};
    font-weight: ${theme.typography.fontWeight.one};
    border: 1px solid ${error ? theme.colors.red.one : theme.colors.gray.one};
    border-radius: ${theme.border.radius.one};
    transition: all ${theme.transitions.duration['150']} ${theme.transitions.easing.easeInOut};
    will-change: auto;
    &:focus {
      outline: none;
      border-color: ${error ? theme.colors.red.one : theme.colors.blue.one};
    }
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 20px;
    }
    &:focus ~ ${InputLabel} {
      opacity: 0.5;
      font-size: ${theme.typography.fontSize.one};
      transform: translate3d(0, -10px, 0);
    }
    &:not(:placeholder-shown) ~ ${InputLabel} {
      opacity: 0.5;
      font-size: ${theme.typography.fontSize.one};
      transform: translate3d(0, -10px, 0);
    }
    &:-webkit-autofill {
      background-clip: content-box;
      box-shadow: inset 0 0 0 60px ${theme.colors.white.one};
      -webkit-text-fill-color: ${theme.colors.black.one};
    }
  `};
`;

export const InputEye = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  ${({ isShow }: {isShow: boolean}) => css`
    display: ${isShow ? 'block' : 'none'};
  `};
`;
