import styled, { css } from 'styled-components';

type PhoneLabelProps = {
  error: string | undefined
};

export const Phone = styled.div`
  position: relative;
`;

export const PhoneLabelText = styled.span`
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  line-height: 24px;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.navigo};
   `};
`;

export const PhoneLabelNote = styled.span`
  font-size: 14px;
  display: block;
  color: #858FAD;
  margin-bottom: 10px;
  line-height: 22px;
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.navigo};
   `};
`;

export const PhoneLabel = styled.label<PhoneLabelProps>`
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
  cursor: text;
  ${({ theme, error }) => css`
    font-weight: ${theme.typography.fontWeight.one};
    color: ${theme.colors.black.one};

    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown {
      border-color: ${error ? theme.colors.red.one : '#E0E3EB'};
    }

    .react-tel-input .flag-dropdown {
      border-right-color: #E0E3EB;
      transition: all .2s linear;
    }
    
    .react-tel-input .flag-dropdown:hover {
      background-color: #EDEFF3;
    }

    .react-tel-input .form-control:focus + .flag-dropdown {
      border-color: #2671D9;
      border-right-color: #E0E3EB;
    }

    .react-tel-input .form-control:focus {
      border-color: #2671D9;
    }
  `};
`;
