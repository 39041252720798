import React from 'react';
import { StyledSvg, StyledPath } from './loader-svg.styled';

export const LoaderSVG = () => (
  <StyledSvg viewBox="25 25 50 50">
    <StyledPath
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </StyledSvg>
);
