import React from 'react';

export const Star = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.904297" y="0.90625" width="23" height="23" rx="11.5" fill="white" />
    <g clipPath="url(#clip0_5347_5971)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8366 7.72656C10.9734 7.42547 11.1941 7.17014 11.4722 6.99108C11.7502 6.81202 12.074 6.7168 12.4047 6.7168C12.7355 6.7168 13.0592 6.81202 13.3373 6.99108C13.6153 7.17014 13.836 7.42547 13.9728 7.72656L14.7853 9.43281L16.5972 9.70094C18.0272 9.91219 18.6041 11.7322 17.5641 12.7884L16.256 14.1047L16.5647 15.9734C16.8085 17.4522 15.3053 18.5734 14.0297 17.8828L12.4047 16.9972L10.7797 17.8828C9.50409 18.5816 8.00909 17.4522 8.25284 15.9734L8.56159 14.1047L7.24534 12.7884C6.21347 11.7322 6.78222 9.91219 8.22034 9.70094L10.0241 9.42469L10.8366 7.72656ZM13.2172 10.2291L12.4047 8.52281L11.5922 10.2291C11.4707 10.4894 11.2872 10.7159 11.0577 10.8888C10.8282 11.0616 10.5597 11.1754 10.276 11.2203L8.46409 11.4884L9.78034 12.8128C10.1866 13.2272 10.3735 13.8284 10.2841 14.4134L9.96722 16.2822L11.5922 15.3966C11.8415 15.2607 12.1208 15.1896 12.4047 15.1896C12.6886 15.1896 12.9679 15.2607 13.2172 15.3966L14.8422 16.2822L14.5335 14.4134C14.4845 14.1265 14.5039 13.832 14.59 13.5539C14.6761 13.2758 14.8265 13.0219 15.0291 12.8128L16.3453 11.4884L14.5335 11.2203C14.2497 11.1754 13.9813 11.0616 13.7518 10.8888C13.5223 10.7159 13.3387 10.4894 13.2172 10.2291Z" fill="#292E3D" />
    </g>
    <defs>
      <clipPath id="clip0_5347_5971">
        <rect width="13" height="13" fill="white" transform="translate(5.9043 5.90625)" />
      </clipPath>
    </defs>
  </svg>

);
