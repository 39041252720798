import React from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorMessageElement from '@/elements/error-message';
import { Subtitle, Title, TitleH1 } from './title.styled';
import { PropsTypes } from './types';

function TitleElement({
  className,
  text,
  subtitle,
  values,
  formError,
  formErrorValues,
  socialError,
}: PropsTypes) {
  return (
    <Title className={className}>
      <TitleH1 withSubtitle={!!subtitle}>
        <FormattedMessage id={text} values={values} />
      </TitleH1>
      {subtitle && (
        <Subtitle>
          <FormattedMessage id={subtitle} />
        </Subtitle>
      )}
      <ErrorMessageElement error={formError || socialError} values={formErrorValues} />
    </Title>
  );
}

export default TitleElement;
