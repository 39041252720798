import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createAnalytics } from '@/services/analytics';
import { createValidate } from '@/services/validate';
import { SOCIAL_ERROR } from '@/constants/error';
import { AUTH_SOCIAL_ERROR, REGISTER_SOCIAL_ERROR } from '@/constants/analytics';
import { Page } from '@/types/page';
import { PropsTypes } from './types';

const { sendAnalytics } = createAnalytics();
const { validate } = createValidate();

export function useSocialError(namePage: PropsTypes) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const error = useMemo(() => validate.social(searchParams.get(SOCIAL_ERROR)), [searchParams]);

  useEffect(() => {
    if (error && !sessionStorage.getItem(SOCIAL_ERROR)) {
      sendAnalytics(namePage === Page.signin ? AUTH_SOCIAL_ERROR : REGISTER_SOCIAL_ERROR);
      sessionStorage.setItem(SOCIAL_ERROR, error);
    } else if (error && sessionStorage.getItem(SOCIAL_ERROR)) {
      sessionStorage.removeItem(SOCIAL_ERROR);
      searchParams.delete(SOCIAL_ERROR);
      history.replace({ search: `?${searchParams.toString()}` });
    }
  }, [error, history, namePage, searchParams]);

  return [error];
}
