import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  ${({ theme }) => css`
    @media(min-width: ${theme.breakpoints.mobile}px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media(min-width: ${theme.breakpoints.laptop}px) {
      padding-right: 60px;
      padding-left: 60px;
    }
  `};
`;
