import React, { memo, useCallback } from 'react';
import { Container } from '@/assets/styles/variables';
import SignUpForm from '@/pages/signup/form';
import SignUpFields from '@/pages/signup/fields';
import SignUpTitle from '@/pages/signup/title';
import SignUpAgreements from '@/pages/signup/agreements';
import SignUpSubmit from '@/pages/signup/submit';
import { Page } from '@/types/page';
import { AGENCY_REGISTRATION_URL } from '@/constants/urls';
import { useSelector } from 'react-redux';
import { selectFullUrlParams } from '@/features/global';
import { REGISTER_AGENCY_REG } from '@/constants/analytics';
import { createAnalytics } from '@/services/analytics';
import { LinkDomElement } from '../elements/links';
import SignUpRightComponent from '../elements/right-component';
import Header from '../elements/header';
import SocialElement from '../elements/social';
import LineElement from '../elements/line';
import { Entry, EntryGrid } from './entry.styled';

const { sendAnalytics } = createAnalytics();

function SignUpEntry() {
  const fullUrlParams = useSelector(selectFullUrlParams);
  const handleClick = useCallback(() => {
    sendAnalytics(REGISTER_AGENCY_REG);
  }, []);
  return (
    <Entry>
      <Container>
        <EntryGrid>
          <Header rightComponent={<SignUpRightComponent />} />
          <SignUpForm>
            <SignUpTitle />
            <SocialElement namePage={Page.signup} />
            <LineElement />
            <SignUpFields />
            <SignUpAgreements />
            <SignUpSubmit />
          </SignUpForm>
          <LinkDomElement withImage className="footer-img" text="Form.link.agencies.registration" href={AGENCY_REGISTRATION_URL + fullUrlParams} onClick={handleClick} external={false} />
        </EntryGrid>
      </Container>
    </Entry>
  );
}

export default memo(SignUpEntry);
