import React from 'react';

import * as Styled from './HeadTitle.styled';

type Props = {
  text: React.ReactNode
};

const HeadTitle = ({ text }: Props) => (
  <Styled.Title>
    {text}
  </Styled.Title>
);

export default HeadTitle;
