import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { StylesTypes } from './types';

export const LinkButton = styled(Link)`
  display: inline-flex;
  border-radius: 5px;
  ${({ theme, variant }: StylesTypes) => {
    switch (variant) {
      case 'one':
        return css`
          min-width: 180px;
          min-height: 60px;
          width: 100%;
          margin-top: auto;
          padding-left: 40px;
          padding-right: 40px;
          color: ${theme.colors.white.one};
          background-color: ${theme.colors.green.one};
          &:hover {
            background-color: ${theme.colors.green.two};
          }
          @media(min-width: ${theme.breakpoints.mobile}px) {
            display: inline-flex;
            width: max-content;
          }
        `;
      default:
        return css`
          min-width: 74px;
          min-height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          color: ${theme.colors.blue.three};
          background-color: #fff;
          border: 1px solid #C2C7D6;
          &:hover {
            background-color: #D4E3F7;
          }
        `;
    }
  }};
`;

export const LinkButtonText = styled.span`
  margin: auto;
`;
