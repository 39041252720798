import { CONFIG } from '@/constants/analytics';
import { NameTypes, SuccessPropsTypes } from '@/types/analytics';
import { reducers } from './reducers';

export * from './push-analytics';

export function sendEventSuccessReg({
  country,
  locale,
  formId,
  sourceUrl,
  userId,
}: SuccessPropsTypes) {
  const data = {
    event: 'registration_form_send',
    formId,
    sourceUrl,
    user_locale: locale,
    user_country: country,
    user_id: userId,
    eventCallback: () => {},
    eventTimeout: 2000,
  };

  window.dataLayer.push(data);
}

export const sendAnalytics = (name: NameTypes) => {
  const reducer = reducers();

  const data = reducer[name](CONFIG);

  window.dataLayer.push(data);
};
