import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFullUrlParams, selectLang, selectLocale } from '@/features/global';
import { selectValues, fetchSignUp } from '@/features/signup';
import { ReturnTypes, PropsTypes } from './types';

export function useSignUp(recaptchaRef: PropsTypes): ReturnTypes {
  const values = useSelector(selectValues);
  const locale = useSelector(selectLocale);
  const lang = useSelector(selectLang);
  const fullUrlParams = useSelector(selectFullUrlParams);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    const recaptchaToken = await recaptchaRef?.current?.executeAsync() as string;
    await recaptchaRef?.current?.reset();

    const updatedValues = { ...values, country: 'RUS' };
    dispatch(fetchSignUp(updatedValues, fullUrlParams, recaptchaToken, locale));
  }, [dispatch, fullUrlParams, locale, recaptchaRef, values]);

  return [lang, handleSubmit];
}
