import styled from 'styled-components';
import { theme } from '@elamajs/ui-kit';

export const Root = styled.div`
  width: 400px;
  max-width: 80vw;
`;

export const MainTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const List = styled.ul`
  padding: 0;
  list-style-position: inside;
  margin-bottom: 0;

  li {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 10px;
    font-size: 13px;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

export const Subtitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  color: ${theme.palette.black[50]};
`;
