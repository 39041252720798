import { PUBLIC_URL } from './urls';

export const API_BASE_URL = `${PUBLIC_URL}/api/`;
export const API_AUTH_USER_URL = `${API_BASE_URL}signin`;
export const API_CREATE_USER_URL = `${API_BASE_URL}signup`;
export const API_RESTORE_PASSWORD_URL = `${API_BASE_URL}restore_password`;
export const API_UPDATE_PASSWORD_URL = `${API_BASE_URL}update_password`;
export const API_CAPTCHA_CHECKOUT = `${API_BASE_URL}captcha`;
export const API_REQUEST_CONFIRMATION_CODE_URL = `${API_BASE_URL}email/confirmation/request`;

export const TRANSLATION_BASE_URL = `${PUBLIC_URL}/translations/`;

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;
export const HTTP_STATUS_INVALID_DATA = 400;
