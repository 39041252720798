import React from 'react';
import { useSelector } from 'react-redux';
import { selectFormCriticalError, selectIsDisableButton, selectLoadFetch } from '@/features/signup';
import { ButtonElement } from '../elements/buttons';
import { Submit, SubmitError } from './submit.styled';
import { submitErrors } from './errors';

function SignUpSubmit() {
  const isDisableButton = useSelector(selectIsDisableButton);
  const loadFetch = useSelector(selectLoadFetch);
  const formError = useSelector(selectFormCriticalError);

  return (
    <Submit>
      {formError && <SubmitError>{submitErrors[formError] || formError}</SubmitError>}
      <ButtonElement text="Зарегистрироваться" type="submit" disabled={isDisableButton || loadFetch} />
    </Submit>
  );
}

export default SignUpSubmit;
