import React from 'react';
import { PASSWORD_MIN_LENGTH } from '@/constants/validate';
import CheckItem from '../CheckItem';

import * as Styled from './PasswordHint.styled';

type Props = {
  value?: string;
};

const specialText = '` ~ ! @ # $ % ^ & * ( ) _ - + = { } [ ] \\ | : ; " \' < > , . ? /';

const PasswordHint: React.FC<Props> = (props) => {
  const { value = '' } = props;

  const hasMinLength = value.length >= PASSWORD_MIN_LENGTH;
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasDecimal = /\d/.test(value);
  const hasSpecial = /[^A-Za-z\d\s]/.test(value);

  return (
    <Styled.Root>
      <Styled.MainTitle>Требования к паролю</Styled.MainTitle>
      <Styled.List>
        <CheckItem
          title="Минимум 8 символов"
          active={hasMinLength}
        />
        <CheckItem
          title="Хотя бы одна заглавная буква латинского алфавита"
          subtitle="Проверьте, что Caps Lock отключен"
          active={hasUpperCase}
        />
        <CheckItem
          title="Хотя бы одна строчная буква латинского алфавита"
          subtitle="Проверьте, что Caps Lock отключен"
          active={hasLowerCase}
        />
        <CheckItem
          title="Хотя бы одна цифра"
          active={hasDecimal}
        />
        <CheckItem
          title="Хотя бы один спецсимвол"
          subtitle={specialText}
          active={hasSpecial}
        />
      </Styled.List>
    </Styled.Root>
  );
};

export default PasswordHint;
