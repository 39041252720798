import styled, { css } from 'styled-components';

export const Container = styled.div<{isCenter: boolean}>`
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 30px 0 40px 90px;

  ${({ isCenter }) => isCenter
  && css`
      justify-content: center;
    `
};


  @media (max-width: 1339px) {
    justify-content: center;
    padding-left: 0;
    margin-right: -10px;
  }

  @media (max-width: 1059px) {
    padding-top: 80px;
    margin-right: 0;
  }

  @media (max-width: 519px) {
    padding-top: 130px;
  }
`;
