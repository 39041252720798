import styled, { css } from 'styled-components';

export const Line = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    ${({ theme }) => css`
      background-color: ${theme.colors.gray.one};
    `};
  }

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.mobile}px) {
      justify-content: center;
    }
  `};
`;

export const LineText = styled.span`
  position: relative;
  padding-right: 10px;
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize.one};
    line-height: ${theme.typography.lineHeight.one};
      background-color: ${theme.colors.white.one};
  `};
`;
