import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LinkButton, LinkButtonText } from './link-button.styled';
import { PropsTypes } from './types';

function LinkButtonElement({
  href,
  text,
  variant,
  onClick,
  name,
}: PropsTypes) {
  const location = useLocation();

  return (
    <LinkButton
      to={{ pathname: href, search: location.search }}
      variant={variant}
      onClick={onClick}
      data-name={name}
    >
      <LinkButtonText>
        <FormattedMessage id={text} />
      </LinkButtonText>
    </LinkButton>
  );
}

export default LinkButtonElement;
