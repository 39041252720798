import styled, { css } from 'styled-components';

export const Fields = styled.div`
  display: grid;
  ${({ theme }) => css`
    gap: 25px;
    margin-bottom: 30px;
    @media(max-width: ${theme.breakpoints.mobile}px) {
      gap: 40px;
    }
  `};
`;
