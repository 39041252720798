import { isValidPhoneNumber } from 'react-phone-number-input';
import { PropsTypes, ReturnTypes } from '@/types/validations';
import { isEmail } from '@/utils/is-email';
import { isRuLocale } from '@/utils/is-locale';
import { mapLocale } from '@/maps/mapLocale';
import { LocaleTypes } from '@/types/locale';
import { PropsValidateTypes } from '@/features/signup/types';
import { PASSWORD_REGEX, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '@/constants/validate';

const restore = (values: PropsTypes): ReturnTypes => {
  let isValid = true;

  if (!values.login) {
    isValid = false;
  } else if (!isEmail(values.login)) {
    isValid = false;
  }

  return isValid;
};

const signin = (values: PropsTypes): ReturnTypes => {
  let isValid = true;

  if (!values.email) {
    isValid = false;
  }

  if (!values.password) {
    isValid = false;
  } else
  // Для обратной совместимости со старыми паролями (используется на странице логина)
  if (values.password.length < 3) {
    isValid = false;
  } else if (values.password.length > PASSWORD_MAX_LENGTH) {
    isValid = false;
  }

  return isValid;
};

const signup = ({ values, checkbox }: PropsValidateTypes): ReturnTypes => {
  let isValid = true;

  if (!values.country) isValid = false;

  if (!values.email) {
    isValid = false;
  } else if (!isEmail(values.email)) {
    isValid = false;
  }

  if (!values.phone || !isValidPhoneNumber(values.phone)) {
    isValid = false;
  }

  if (!checkbox.one) {
    isValid = false;
  }

  if (!checkbox.two && !isRuLocale(mapLocale(values.country as LocaleTypes))) {
    isValid = false;
  }

  return isValid;
};

const update = (values: PropsTypes): ReturnTypes => {
  const { newPassword = '' } = values;
  if (!newPassword) {
    return false;
  }
  if (newPassword.length < PASSWORD_MIN_LENGTH || newPassword.length > PASSWORD_MAX_LENGTH) {
    return false;
  }
  return Boolean(newPassword.match(PASSWORD_REGEX));
};

export const social = (error: string | null) => {
  if (!error) return '';

  const IsValidError = /^forms.soc_auth.[a-z_]+$/.test(error) || /^criticalError$/.test(error);

  return IsValidError ? `Form.error.${error}` : '';
};

export const validate = {
  restore,
  signin,
  signup,
  update,
  social,
};
