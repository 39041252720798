import React, { ChangeEvent } from 'react';
import { LinkDomElement } from '../links';
import { Agreement, AgreementInput, AgreementIndicator } from './agreement.styled';
import { PropsTypes, NameTypes } from './types';

function AgreementElement({
  href,
  hrefAgreement,
  onChange,
  name,
  checked,
  error,
}: PropsTypes) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ name: event.target.name as NameTypes, checked: event.target.checked });
  };

  return (
    <Agreement>
      <AgreementInput
        type="checkbox"
        onChange={handleChange}
        name={name}
        checked={checked}
        id={name}
      />
      <AgreementIndicator error={error}>
        Я&nbsp;даю
        {' '}
        <LinkDomElement
          href={hrefAgreement}
          text="согласие"
        />
        {' '}
        на&nbsp;обработку моих персональных данных в&nbsp;соответствии с
        {' '}
        <LinkDomElement
          href={href}
          text="Политикой конфиденциальности"
        />
      </AgreementIndicator>
    </Agreement>
  );
}

export default AgreementElement;
