import { PayloadAction } from '@reduxjs/toolkit';
import { createAnalytics } from '@/services/analytics';
import { createValidate } from '@/services/validate';
import { getParamsObject } from '@/utils/get-params-object';
import { NEW_ELAMA_URL } from '@/constants/urls';
import { CRITICAL_ERROR } from '@/constants/error';
import { FORM_DATA_PARAMS } from '@/constants/params';
import { REGISTER_SUCCESS, REGISTER_ERROR } from '@/constants/analytics';
import { initialState } from './state';
import {
  ActionValuesTypes,
  InitialStateTypes,
  ActionCheckboxTypes,
  ActionSuccessRegisterTypes,
  ActionErrorRegisterTypes,
  ActionErrorsValueTypes,
} from './types';

const { sendAnalytics, sendEventSuccessReg, pushAnalytics } = createAnalytics();
const { fields, validate } = createValidate();

export const addValues = (state: InitialStateTypes, action: PayloadAction<ActionValuesTypes>) => {
  const { name, value } = action.payload;
  const values = { ...state.values, [name]: value };

  state.isDisableButton = !validate.signup({ values, checkbox: state.checkbox });
  state.values = values;

  if (state.errorsValues[name]) state.errorsValues[name] = '';

  if (state.formError === 'Form.error.userRegistrationError') {
    state.formError = '';
  }

  if (name === 'country') {
    state.checkbox = { one: false, two: false };
    state.errorsCheckbox = { one: '', two: '' };
    state.isDisableButton = true;
  }
};

export const addCheckbox = (
  state: InitialStateTypes,
  action: PayloadAction<ActionCheckboxTypes>,
) => {
  const { name, checked } = action.payload;
  const checkbox = { ...state.checkbox, [name]: checked };
  const errorsCheckbox = {
    ...state.errorsCheckbox,
    [name]: fields.checkbox[name](checkbox, state.values.country as string),
  };

  state.checkbox = checkbox;
  state.errorsCheckbox = errorsCheckbox;
  state.isDisableButton = !validate.signup({
    values: state.values,
    checkbox: { ...checkbox, [name]: checked },
  });
};

export const addErrorsValue = (
  state: InitialStateTypes,
  action: PayloadAction<ActionErrorsValueTypes>,
) => {
  const name = action.payload;
  const { values, checkbox } = state;

  state.errorsValues = {
    ...state.errorsValues,
    [name]: fields[name](values[name] as string),
  };
  state.isDisableButton = !validate.signup({ values, checkbox });
};

export const startRegister = (state: InitialStateTypes) => {
  state.loadFetch = true;
};

export const successRegister = (
  state: InitialStateTypes,
  action: PayloadAction<ActionSuccessRegisterTypes>,
) => {
  const {
    backUrl,
    userId,
    locale,
    fullUrlParams,
  } = action.payload;

  const { formId, sourceUrl } = getParamsObject(FORM_DATA_PARAMS, fullUrlParams);
  const { country } = state.values;

  sendAnalytics(REGISTER_SUCCESS);
  sendEventSuccessReg({
    country: country as string,
    locale,
    formId,
    sourceUrl,
    userId,
  });

  (async () => {
    await pushAnalytics(userId);
  })();

  state.redirectURL = backUrl || NEW_ELAMA_URL;
};

export const errorRegister = (
  state: InitialStateTypes,
  action: PayloadAction<ActionErrorRegisterTypes>,
) => {
  sendAnalytics(REGISTER_ERROR);

  state.loadFetch = false;
  if (action.payload === 'userRegistrationError') {
    state.formError = `Form.error.${action.payload}`;
  } else {
    state.formCriticalError = `Form.error.${action.payload}` || CRITICAL_ERROR;
  }
};

export const stateClear = (state: InitialStateTypes) => {
  state.values = initialState.values;
  state.checkbox = initialState.checkbox;
  state.errorsValues = initialState.errorsValues;
  state.errorsCheckbox = initialState.errorsCheckbox;
  state.loadFetch = initialState.loadFetch;
  state.redirectURL = initialState.redirectURL;
  state.formError = initialState.formError;
  state.formCriticalError = initialState.formCriticalError;
};
