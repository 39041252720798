import styled, { css } from 'styled-components';

export const Title = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 40px;

  ${({ theme }) => css`
    font-size: 36px;
    line-height: 42px;
    font-weight: 400;
    font-family: ${theme.typography.fontFamily.coolvetica};
    @media(max-width: ${theme.breakpoints.mobile}px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 30px;
    }
  `};
`;
