/**
 * Match latin lowercase, uppercase, number and special symbol
 * Пояснение:
 * (?=.*[0-9]) - строка содержит хотя бы одно число;
 * (?=.*[^A-Za-z\d\s]) - строка содержит хотя бы один спецсимвол;
 * (?=.*[a-z]) - строка содержит хотя бы одну латинскую букву в нижнем регистре;
 * (?=.*[A-Z]) - строка содержит хотя бы одну латинскую букву в верхнем регистре;
 */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z\d\s]).*/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const LOWERCASE_REGEX = /[a-z]/;
export const DECIMAL_REGEX = /\d/;
export const SPECIAL_CHARACTERS_REGEX = /[^A-Za-z\d\s]/;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 50;
