import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/app/store';
import { restorePassword } from '@/api/restore-password';
import { createFormData } from '@/utils/create-form-data';
import { HTTP_STATUS_OK } from '@/constants/api';
import { initialState } from './state';
import {
  addValues,
  addErrors,
  startRestore,
  successRestore,
  errorRestore,
  successCancel,
  stateClear,
} from './actions';
import { RootSignInState, ValuesTypes } from './types';

const restoreSlice = createSlice({
  name: 'restore',
  initialState,
  reducers: {
    getValues: addValues,
    getErrors: addErrors,
    restoreStart: startRestore,
    restoreSuccess: successRestore,
    restoreError: errorRestore,
    cancelSuccess: successCancel,
    clearState: stateClear,
  },
});

export const {
  getValues,
  getErrors,
  restoreStart,
  restoreSuccess,
  restoreError,
  cancelSuccess,
  clearState,
} = restoreSlice.actions;

export const restoreName = restoreSlice.name;

export const fetchRestore = (
  values: ValuesTypes,
  fullUrlParams: string,
  recaptchaToken: string,
): AppThunk => async (dispatch: AppDispatch) => {
  const formData = createFormData({ values, fullUrlParams, recaptchaToken });

  dispatch(restoreStart());

  try {
    const success = await restorePassword(formData);

    if (success.status !== HTTP_STATUS_OK) throw new Error('');

    dispatch(restoreSuccess());
  } catch (err) {
    dispatch(restoreError(err.response?.data?.code));
  }
};

export const selectValues = (state: RootSignInState) => state.restore.values;
export const selectErrors = (state: RootSignInState) => state.restore.errors;
export const selectFormError = (state: RootSignInState) => state.restore.formError;
export const selectIsSubmitting = (state: RootSignInState) => state.restore.isSubmitting;
export const selectIsDisableButton = (state: RootSignInState) => state.restore.isDisableButton;
export const selectLoadFetch = (state: RootSignInState) => state.restore.loadFetch;

export default restoreSlice.reducer;
