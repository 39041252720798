import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Attention, Button, Loader } from '@elamajs/ui-kit';
import { INITIAL_VALUES, SCHEMA } from '@/schemas/update';
import {
  REACT_APP_RECAPTCHA_PUBLIC_KEY, RECAPTCHA_BADGE,
  RECAPTCHA_ENABLED,
  RECAPTCHA_SIZE,
  RECAPTCHA_THEME,
} from '@/constants/re-captcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { getStateUrl } from '@/utils/get-state';
import { createFormData } from '@/utils/create-form-data';
import { updatePassword } from '@/api/update-password';
import { HTTP_STATUS_OK } from '@/constants/api';
import { ROUTE_RESTORE_PASSWORD, ROUTE_SIGN_IN } from '@/constants/routes';
import { getParamsObject } from '@/utils/get-params-object';
import { UPDATE_PASSWORD_PARAMS } from '@/constants/params';
import HeadTitle from '../HeadTitle';
import UpdateForm from '../UpdateForm';

import * as Styled from './UpdateContent.styled';

const UpdateContent = () => {
  const { login, confirmationCode } = getParamsObject(UPDATE_PASSWORD_PARAMS);
  const recaptchaRef = useRef<ReCAPTCHA>();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const { lang, fullUrlParams } = getStateUrl();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const recaptchaToken = await recaptchaRef?.current?.executeAsync() as string;
    const data = { newPassword: values.newPassword.trim(), login, confirmationCode };
    const formData = createFormData({ values: data, fullUrlParams, recaptchaToken });

    try {
      const success = await updatePassword(formData);
      if (success.status !== HTTP_STATUS_OK) throw new Error('');
      setIsUpdateSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      recaptchaRef?.current?.reset();
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    history.push(ROUTE_SIGN_IN);
  };

  const handleRestore = () => {
    history.push(ROUTE_RESTORE_PASSWORD);
  };

  if (!login || !confirmationCode) return <Styled.Loader as={Loader} />;

  if (error) {
    return (
      <Styled.Body>
        <HeadTitle text={(
          <>
            Пароль не изменился
          </>
        )}
        />
        <Styled.Text>
          Попробуйте запросить новую ссылку, и изменить пароль еще раз
        </Styled.Text>
        <Button onClick={handleRestore}>Получить новую ссылку</Button>
      </Styled.Body>
    );
  }

  if (isUpdateSuccess) {
    return (
      <Styled.Body>
        <HeadTitle text={(
          <>
            Пароль установлен
          </>
        )}
        />
        <Styled.Text>
          Войдите в аккаунт
          {' '}
          { login }
          {' '}
          с помощью этого пароля
        </Styled.Text>
        <Button onClick={handleSignIn}>Войти</Button>
      </Styled.Body>
    );
  }

  return (
    <Styled.Body>
      <HeadTitle
        text={(
          <>
            Придумайте пароль для аккаунта
            {' '}
            { login }
          </>
        )}
      />
      <Styled.Attention as={Attention} type="error" isOpened={!!error}>
        {error}
      </Styled.Attention>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <UpdateForm {...props} isLoading={isLoading} />
        )}
      </Formik>
      {RECAPTCHA_ENABLED && (
        <ReCAPTCHA
          ref={recaptchaRef}
          hl={lang}
          size={RECAPTCHA_SIZE}
          theme={RECAPTCHA_THEME}
          sitekey={REACT_APP_RECAPTCHA_PUBLIC_KEY}
          badge={RECAPTCHA_BADGE}
        />
      )}
    </Styled.Body>
  );
};

export default UpdateContent;
