const easing = {
  easeInOut: 'ease-in-out',
  easeOut: 'ease-out',
  easeIn: 'ease-in',
  linear: 'linear',
};

const duration = {
  150: '100ms',
  200: '200ms',
};

export const transitions = {
  easing,
  duration,
};
