import uiKitTheme from '@elamajs/ui-kit';
import {
  colors,
  transitions,
  animations,
  typography,
  breakpoints,
  border,
} from './variables';

/** Для корректной работы некоторых компонентов (HelpBubble) требуется добавить тему ui-kit */
const defaultTheme = {
  ...uiKitTheme,
  colors,
  transitions: { ...transitions },
  animations,
  typography: { ...typography },
  breakpoints,
  border,
};

export default defaultTheme;
