import {
  OFFER_LINK_PRIVATE_POLICY_RUS,
  OFFER_AGREEMENT,
} from '@/constants/urls';

export const AGREEMENT = {
  nameCheckbox: 'one',
  nameLink: 'agreeLink',
  href: OFFER_LINK_PRIVATE_POLICY_RUS,
  hrefAgreement: OFFER_AGREEMENT,
  text: 'SignUpForm.agree',
  linkText: 'SignUpForm.agree.agreeLinkText',
};
