import styled, { css } from 'styled-components';

export const Entry = styled.section`
  position: relative;
  padding: 40px 0 90px;
  ${({ theme }) => css`
    color: ${theme.colors.black.one};
    background-color: ${theme.colors.white.one};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      padding: 40px 0 30px;
      min-height: 100vh;
    }
  `};
`;

export const EntryGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
