import React from 'react';
import { FormikProps } from 'formik';
import { Button, InputText } from '@elamajs/ui-kit';
import { FIELD } from '@/schemas/restore';
import Field from '../Field';

import * as Styled from './RestoreForm.styled';

type Props = FormikProps<FormData> & {
  isLoading: boolean
  isRestoreSuccess: boolean
  error: string
};

const RestoreForm = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  isValid,
  isLoading,
  isRestoreSuccess,
  handleBlur,
  dirty,
  error,
}: Props) => {
  const handleChange = (
    value: string | boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue(event.target.name, value);
  };

  if (isRestoreSuccess) {
    return null;
  }

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Field text="Электронная почта">
        <InputText
          name={FIELD.LOGIN}
          width="100%"
          placeholder="Ваш логин"
          value={values[FIELD.LOGIN]}
          errorMessage={(touched[FIELD.LOGIN] && errors[FIELD.LOGIN]) || error}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      <Button type="submit" disabled={!(isValid && dirty)} loading={isLoading}>Продолжить</Button>
    </Styled.Form>
  );
};

export default RestoreForm;
