type ParamsProps = string[];
type FullParamsProps = string;
type ReturnProps = {[key: string]: string};

export const getParamsObject = (
  params: ParamsProps,
  fullUrlParams: FullParamsProps = window.location.search,
):ReturnProps => params.reduce((acc, item) => {
  const foundParams = new URLSearchParams(fullUrlParams);

  return foundParams.get(item) ? { ...acc, [item]: foundParams.get(item) } : acc;
}, {});
