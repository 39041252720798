const fontSize = {
  one: '13px',
  two: '14px',
  three: '16px',
  four: '22px',
  five: '28px',
  six: '12px',
  seven: '10px',
};

const lineHeight = {
  one: '18px',
  two: '21px',
  three: '24px',
  four: '26px',
  five: '27px',
  six: '33px',
  seven: '22px',
  eight: '14px',
};

const fontWeight = {
  one: 300,
  two: 400,
  three: 500,
};

const fontFamily = {
  one: '"Museo-Sans-Cyrl", Arial, Helvetica, sans-serif',
  navigo: '"Navigo", Arial, Helvetica, sans-serif',
  coolvetica: '"coolvetica", Arial, Helvetica, sans-serif',
};

export const typography = {
  lineHeight,
  fontSize,
  fontWeight,
  fontFamily,
};
