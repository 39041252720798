import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/app/store';
import { createUser } from '@/api/create-user';
import { createFormData } from '@/utils/create-form-data';
import { HTTP_STATUS_OK } from '@/constants/api';
import { DefaultValuesTypes } from '@/types/values';
import { LocaleTypes } from '@/types/locale';
import { RootSignUpStateTypes } from './types';
import { initialState } from './state';
import {
  addValues,
  addCheckbox,
  addErrorsValue,
  startRegister,
  successRegister,
  errorRegister,
  stateClear,
} from './actions';

const signUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    getValues: addValues,
    getCheckbox: addCheckbox,
    getErrorsValue: addErrorsValue,
    registerUserStart: startRegister,
    registerUserSuccess: successRegister,
    registerUserError: errorRegister,
    clearState: stateClear,
  },
});

export const signUpName = signUpSlice.name;

export const {
  getValues,
  getCheckbox,
  getErrorsValue,
  registerUserStart,
  registerUserSuccess,
  registerUserError,
  clearState,
} = signUpSlice.actions;

export const fetchSignUp = (
  values: DefaultValuesTypes,
  fullUrlParams: string,
  recaptchaToken: string,
  locale: LocaleTypes,
): AppThunk => async (dispatch: AppDispatch) => {
  const formData = createFormData({ values, fullUrlParams, recaptchaToken });

  dispatch(registerUserStart());

  try {
    const success = await createUser(formData);

    // try так же может отвалиться и по таймайту в 10 сек
    // ./src/utils/ajax.ts
    if (success.status !== HTTP_STATUS_OK) throw new Error('');

    dispatch(
      registerUserSuccess({
        ...JSON.parse(success.request.response),
        locale,
        fullUrlParams,
      }),
    );
  } catch (err) {
    dispatch(registerUserError(err?.response?.data?.code));
  }
};

export const selectValues = (state: RootSignUpStateTypes) => state.signup.values;
export const selectValuesCountry = (state: RootSignUpStateTypes) => state.signup.values.country;
export const selectCheckbox = (state: RootSignUpStateTypes) => state.signup.checkbox;
export const selectFormError = (state: RootSignUpStateTypes) => state.signup.formError;
export const selectFormCriticalError = (state: RootSignUpStateTypes) => state.signup.formCriticalError;
export const selectErrorsValues = (state: RootSignUpStateTypes) => state.signup.errorsValues;
export const selectErrorsCheckbox = (state: RootSignUpStateTypes) => state.signup.errorsCheckbox;
export const selectRedirectURL = (state: RootSignUpStateTypes) => state.signup.redirectURL;
export const selectIsDisableButton = (state: RootSignUpStateTypes) => state.signup.isDisableButton;
export const selectLoadFetch = (state: RootSignUpStateTypes) => state.signup.loadFetch;

export default signUpSlice.reducer;
