import { InitialStateTypes } from './types';

export const initialState: InitialStateTypes = {
  values: { login: '' },
  errors: { login: '' },
  isSubmitting: false,
  loadFetch: false,
  isDisableButton: true,
  formError: '',
};
