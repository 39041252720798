import { useEffect } from 'react';
import { PropsTypes, ReturnTypes } from './types';

export function useHead({ translations, lang, title }: PropsTypes): ReturnTypes {
  useEffect(() => {
    if (!translations) {
      document.documentElement.lang = lang;
    } else {
      document.title = translations[title];
    }
  }, [lang, title, translations]);
}
