import styled from 'styled-components';

export const Field = styled.div`
  span {
    white-space: normal;
  }
`;

export const Label = styled.div`
  margin-bottom: 10px;
  display: inline-flex;
  position: relative;

  &:after {
    content: '*';
    position: absolute;
    top: 0;
    right: -8px;
    color:  ${({ theme }) => theme.palette.red[60]};
  }
`;
