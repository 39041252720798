import styled, { keyframes } from 'styled-components';

const loaderRotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const loaderDash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const StyledSvg = styled.svg`
  transform-origin: center center;
  animation: ${loaderRotate} 2s ease-in-out infinite;
`;

export const StyledPath = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: currentColor;
  animation: ${loaderDash} 1.5s ease-in-out infinite;
`;
