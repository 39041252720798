import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import arrow from '@/assets/images/arrow-round.svg';
import { LinkDom } from './link.styled';
import { PropsTypes } from './types';

function LinkDomElement({
  className,
  href,
  text,
  onClick,
  withImage,
  external = true,
}: PropsTypes) {
  const target = useRef(external ? { target: '_blank', rel: 'nofollow noreferrer' } : {});

  return (
    <LinkDom
      className={className}
      href={href}
      {...target.current}
      onClick={onClick}
    >
      <FormattedMessage id={text} />
      {withImage && <img src={arrow} alt="" />}
    </LinkDom>
  );
}

export default LinkDomElement;
