import {
  PropsCreateUrlTypes,
  PropsCheckPathnameTypes,
  PropsSetQueryTypes,
  ReturnTypes,
} from './types';

export const createUrl = ({ url }: PropsCreateUrlTypes): ReturnTypes => {
  const newUrl = new URL(url);
  const params = new URLSearchParams(newUrl.search);

  return {
    checkPathname: ({ pathname }: PropsCheckPathnameTypes) => newUrl.pathname === pathname,
    setQuery: ({ queryKey, queryValue }: PropsSetQueryTypes) => {
      params.set(queryKey, queryValue);
      newUrl.search = params.toString();

      return newUrl.href;
    },
  };
};
