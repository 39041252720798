import styled, { css } from 'styled-components';

export const Form = styled.form`
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
`;

export const FormGrid = styled.div`
  display: grid;
  ${({ theme }) => css`
    grid-template-rows: min-content min-content 24px 150px 26px 90px;
    gap: 20px;
    @media(min-width: ${theme.breakpoints.mobile}px) {
      grid-template-rows: min-content 50px 24px 140px 26px 90px;
      gap: 30px;
    }
  `};
`;
