import styled, { css } from 'styled-components';

export const Entry = styled.section`
  position: relative;
  padding: 20px 0 90px;
  ${({ theme }) => css`
    color: ${theme.colors.black.one};
    background-color: ${theme.colors.white.one};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      padding: 80px 0 30px;
    }
  `};
`;

export const EntryGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  ${({ theme }) => css`
    gap: 60px;
    @media(min-width: ${theme.breakpoints.mobile}px) {
      gap: 70px;
    }
  `};
`;
