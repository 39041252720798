import { PayloadAction } from '@reduxjs/toolkit';
import { createAnalytics } from '@/services/analytics';
import { createValidate } from '@/services/validate';
import { CRITICAL_ERROR } from '@/constants/error';
import { RESTORE_SUCCESS } from '@/constants/analytics';
import {
  ActionValuesTypes,
  ActionErrorsTypes,
  InitialStateTypes,
  ActionErrorFetch,
} from './types';

const { sendAnalytics } = createAnalytics();
const { fields, validate } = createValidate();

export const addValues = (state: InitialStateTypes, action: PayloadAction<ActionValuesTypes>) => {
  const { name, value } = action.payload;

  state.isDisableButton = !validate.restore({ ...state.values, [name]: value });
  state.values[name] = value;

  if (state.errors[name]) state.errors[name] = '';
};

export const addErrors = (state: InitialStateTypes, action: PayloadAction<ActionErrorsTypes>) => {
  const name = action.payload;

  state.errors = {
    ...state.errors,
    [name]: fields[name](state.values[name] as string),
  };
  state.isDisableButton = !validate.restore(state.values);
};

export const startRestore = (state: InitialStateTypes) => {
  state.loadFetch = true;
};

export const successRestore = (state: InitialStateTypes) => {
  sendAnalytics(RESTORE_SUCCESS);

  state.isSubmitting = true;
  state.loadFetch = false;
};

export const errorRestore = (state: InitialStateTypes, action: PayloadAction<ActionErrorFetch>) => {
  state.loadFetch = false;

  state.formError = `Form.error.${action.payload || CRITICAL_ERROR}`;
};

export const successCancel = (state: InitialStateTypes) => {
  state.isSubmitting = false;
};

export const stateClear = (state: InitialStateTypes) => {
  state.values.login = '';
  state.errors.login = '';
  state.isSubmitting = false;
  state.loadFetch = false;
  state.formError = '';
};
