import React from 'react';
import { IconNameCommon, IconNameTypes } from '@/types/icon-name';
import {
  YandexSVG,
  GoogleSVG,
  EyeHide,
  EyeShow,
  ArrowLeft,
  Telegram,
  Star,
  Lines,
  Growth,
  Yavk,
  Systems,
} from '@/assets/svg-inline';

export function getIcon(name: IconNameTypes) {
  switch (name) {
    case 'systems':
      return <Systems />;
    case 'yavk':
      return <Yavk />;
    case 'growth':
      return <Growth />;
    case 'lines':
      return <Lines />;
    case 'star':
      return <Star />;
    case 'telegram':
      return <Telegram />;
    case 'google':
      return <GoogleSVG />;
    case 'yandex':
      return <YandexSVG />;
    case 'eye-hide':
      return <EyeHide />;
    case 'eye-show':
      return <EyeShow />;
    case IconNameCommon.arrowLeft:
      return <ArrowLeft />;
    default:
      return <div>no icon</div>;
  }
}
