import styled, { css } from 'styled-components';

export const MobileDetail = styled.div`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    font-weight: ${theme.typography.fontWeight.one};
    @media(min-width: ${theme.breakpoints.mobile}px) {
      display: none;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
    }
  `};
`;
