import React from 'react';

export function EyeShow() {
  return (
    <svg width="17" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 0C4.636 0 1.337 2.488 0 6c1.337 3.512 4.636 6 8.5 6s7.163-2.488 8.5-6c-1.337-3.512-4.636-6-8.5-6zm0 10c-2.133 0-3.864-1.792-3.864-4S6.367 2 8.5 2s3.864 1.792 3.864 4-1.731 4-3.864 4zm0-6.4C7.217 3.6 6.182 4.672 6.182 6c0 1.328 1.035 2.4 2.318 2.4 1.283 0 2.318-1.072 2.318-2.4 0-1.328-1.035-2.4-2.318-2.4z"
        fill="#C6CAD8"
      />
    </svg>
  );
}
