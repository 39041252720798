import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonText,
  ButtonLoader,
  LoaderBubble,
} from './button.styled';
import { PropsTypes } from './types';

function ButtonElement({
  text,
  loading,
  ...buttonProps
}: PropsTypes) {
  return (
    <Button loading={loading} {...buttonProps}>
      <ButtonText>
        <FormattedMessage id={text} />
      </ButtonText>
      {loading && (
        <ButtonLoader>
          <LoaderBubble />
          <LoaderBubble />
          <LoaderBubble />
        </ButtonLoader>
      )}
    </Button>
  );
}

export default ButtonElement;
