import { DefaultTheme } from 'styled-components';
import { Page } from '@/types/page';

export type StylesTypes = {
  theme: DefaultTheme;
  ruLang: boolean;
};

export enum SocialName {
  yandex = 'yandex',
  google = 'google',
}

export type PropsTypes = {
  namePage: Page.signin | Page.signup;
};
