import React from 'react';
import { useHead } from '@/hooks/useHead';
import { ThemeProvider } from 'styled-components';
import { theme } from '@elamajs/ui-kit';
import { RESTORE_PAGE_TITLE } from '@/constants/title';
import { useTranslations } from '@/hooks/useTranslations';
import Header from '../../components/Header';
import ErrorBoundary from '../../components/ErrorBoundary';
import RestoreContent from '../../components/RestoreContent';
import RightPanel from '../../components/RightPanel';
import GlobalStyleNew from '../../styles/_global';

import * as Styled from './Restore.styled';

const Restore = () => {
  const translations = useTranslations();
  useHead({ translations, lang: 'ru', title: RESTORE_PAGE_TITLE });
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyleNew />
      <ErrorBoundary>
        <Styled.Content>
          <Header />
          <RightPanel isCenter>
            <RestoreContent />
          </RightPanel>
        </Styled.Content>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Restore;
