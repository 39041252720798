import { createGlobalStyle } from 'styled-components';

import MuseoSansCyrl300woff from '../../fonts/museosanscyrl/MuseoSansCyrl_300.woff';
import MuseoSansCyrl300woff2 from '../../fonts/museosanscyrl/MuseoSansCyrl_300.woff2';
import MuseoSansCyrl500woff from '../../fonts/museosanscyrl/MuseoSansCyrl_500.woff';
import MuseoSansCyrl500woff2 from '../../fonts/museosanscyrl/MuseoSansCyrl_500.woff2';

import Navigowoff from '../../fonts/navigo/Navigo.woff';
import Navigowoff2 from '../../fonts/navigo/Navigo.woff2';

import Coolveticawoff from '../../fonts/coolvetica/coolvetica_400.woff';
import Coolveticawoff2 from '../../fonts/coolvetica/coolvetica_400.woff2';

export const Fonts = createGlobalStyle`
    @font-face {
      font-display: fallback;
      font-family: 'Museo-Sans-Cyrl';
      src: local('Museo-Sans-Cyrl 300'), url(${MuseoSansCyrl300woff2}) format('woff2'), url(${MuseoSansCyrl300woff}) format('woff');
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-display: fallback;
      font-family: 'Museo-Sans-Cyrl';
      src: local('Museo-Sans-Cyrl 500'), url(${MuseoSansCyrl500woff2}) format('woff2'), url(${MuseoSansCyrl500woff}) format('woff');
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-display: fallback;
      font-family: 'Navigo';
      font-weight: 400;
      font-style: normal;
      src: local('Navigo'), url(${Navigowoff}) format('woff2'), url(${Navigowoff2}) format('woff');
    }

    @font-face {
      font-display: fallback;
      font-family: 'coolvetica';
      font-weight: 400;
      font-style: normal;
      src: local('coolvetica'), url(${Coolveticawoff}) format('woff2'), url(${Coolveticawoff2}) format('woff');
    }
`;
