export const ERRORS = {
  email: 'Аккаунт с такой почтой уже есть',
  phone: 'Аккаунт с таким телефоном уже есть',
  access_denied: 'Включите VPN eLama, чтобы войти в этот аккаунт',
  criticalError: 'Попробуйте еще раз или обратитесь в Службу Заботы',
  wrongCaptcha: 'Неверная reCaptcha, попробуйте снова',
  errorDefault: 'Что-то пошло не так. Обновите страницу или попробуйте позже.',
  errorDefaultSignUp: 'Что-то пошло не так. Обновите страницу или попробуйте зарегистрироваться позже.',
  errorDefaultSignIn: 'Что-то пошло не так. Обновите страницу или попробуйте войти позже.',
  invalid_credentials: 'Неверный логин или пароль',
};
