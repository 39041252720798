import styled from 'styled-components';

export const Content = styled.div`
  padding: 10px;
  display: flex;
  min-height: 100vh;

  @media (max-width: 519px) {
    padding: 0;
    background-color: ${({ theme }) => theme.palette.white};
  }
`;
