export type IconNameSocialTypes = 'google' | 'yandex';
export type IconNameEyeTypes = 'eye-hide' | 'eye-show';
export type IconNameFeatures = 'yavk' | 'systems' | 'telegram' | 'star' | 'lines' | 'growth';

export enum IconNameCommon {
  arrowLeft = 'arrow-left',
}

export type IconNameTypes =
  | IconNameSocialTypes
  | IconNameEyeTypes
  | IconNameFeatures
  | IconNameCommon;
