import React from 'react';
import { Container, Entry, EntryGrid } from '@/assets/styles/variables';
import { ROUTE_SIGN_UP } from '@/constants/routes';
import { Page } from '@/types/page';
import SignInForm from '@/pages/signin/form';
import SignInRightComponent from '@/pages/signin/right-component';
import SignInSubmit from '@/pages/signin/submit';
import SignInFields from '@/pages/signin/fields';
import SignInTitle from '@/pages/signin/title';
import Header from '@/elements/header';
import SocialElement from '@/elements/social';
import LineElement from '@/elements/line';
import SignInForgotPassword from '@/pages/signin/forgot-password';
import MobileDetailElement from '@/elements/mobile-detail';

function SignInEntry() {
  return (
    <Entry>
      <Container>
        <EntryGrid>
          <Header rightComponent={<SignInRightComponent />} />
          <SignInForm>
            <SignInTitle />
            <SocialElement namePage={Page.signin} />
            <LineElement />
            <SignInFields />
            <SignInForgotPassword />
            <SignInSubmit />
            <MobileDetailElement href={ROUTE_SIGN_UP} text="SignInForm.footerTextMobile" textLink="SignInForm.footerLinkTextMobile" />
          </SignInForm>
        </EntryGrid>
      </Container>
    </Entry>
  );
}

export default SignInEntry;
