import styled from 'styled-components';

export const HeaderLogo = styled.a`
  max-height: 40px;
  position: absolute;
  top: 30px;
  left: 90px;
  z-index: 2;

  @media (max-width: 1059px) {
    top: 15px;
    left: 15px;
  }
`;
