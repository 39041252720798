import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  width: max-content;
  &:focus {
    outline: none;
  }
  ${({ theme }) => css`
    color: ${theme.colors.blue.one};

    &:hover{
      color: ${theme.colors.blue.two};
    }
    &:active {
      color: ${theme.colors.blue.two};
    }
  `};
`;
