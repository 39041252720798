import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckbox,
  selectErrorsCheckbox,
  getCheckbox,
} from '@/features/signup';
import { AGREEMENT } from '@/constants/agreement';
import AgreementElement from '../elements/agreement';
import { Agreements } from './agreements.styled';
import { NameTypes, ChangeTypes } from './types';

function SignUpAgreements() {
  const checkbox = useSelector(selectCheckbox);
  const errors = useSelector(selectErrorsCheckbox);
  const dispatch = useDispatch();

  const handleChange = ({ name, checked }: ChangeTypes) => {
    dispatch(getCheckbox({ name, checked }));
  };

  return (
    <Agreements>
      <AgreementElement
        key={`SignUpAgreements__${AGREEMENT.nameLink}`}
        href={AGREEMENT.href}
        hrefAgreement={AGREEMENT.hrefAgreement}
        name={AGREEMENT.nameCheckbox as NameTypes}
        checked={checkbox[AGREEMENT.nameCheckbox as NameTypes]}
        onChange={handleChange}
        error={errors[AGREEMENT.nameCheckbox as NameTypes]}
      />
    </Agreements>
  );
}

export default SignUpAgreements;
