import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from './error-message.styled';
import { PropsTypes } from './types';

function ErrorMessageElement({ error, values }: PropsTypes) {
  if (!error) return <></>;

  return (
    <ErrorMessage>
      <FormattedMessage id={error} values={values} />
    </ErrorMessage>
  );
}

export default ErrorMessageElement;
