import React, { ReactNode } from 'react';
import { IconStatus } from '@elamajs/ui-kit';

import * as Styled from './CheckItem.styled';

type Props = {
  title: ReactNode
  subtitle?: ReactNode
  active?: boolean
};

const CheckItem: React.FC<Props> = (props) => {
  const { title, subtitle, active } = props;

  const type: 'done' | 'not-done' = active ? 'done' : 'not-done';

  return (
    <li>
      <IconStatus size="s" type={type} />
      <div>
        <div>{title}</div>
        {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      </div>
    </li>
  );
};

export default CheckItem;
