import { mapLocale } from '@/maps/mapLocale';
import { mapLang } from '@/maps/mapLang';
import { getCookie } from '@/utils/cookie';
import { isRuLang } from '@/utils/./is-lang';
import { isCorrectLocale } from '@/utils/./is-correct-locale';
import { KEY_LOCALE } from '@/constants/locale';
import { COUNTRY_CODE_DEFAULT } from '@/constants/country-code';
import { CountryCodeTypes } from '@/types/country-code';
import { LocaleTypes } from '@/types/locale';
import { LangTypes } from '@/types/lang';

type StateUrlTypes = {
  locale: LocaleTypes
  lang: LangTypes
  country: string
  fullUrlParams: string
};

export const getCountryCode = (): CountryCodeTypes => (getCookie('countryCode') ? JSON.parse(getCookie('countryCode') as string) : COUNTRY_CODE_DEFAULT);
export const getHashes = (): {[key: string]: string} => (getCookie('hashes') ? JSON.parse(getCookie('hashes') as string) : {});
export const getStateUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const locale = searchParams.get(KEY_LOCALE);
  const { iso3 } = getCountryCode();
  const stateUrl: StateUrlTypes = {
    locale: 'ru',
    lang: 'ru',
    country: '',
    fullUrlParams: '',
  };

  if (locale === null || (locale !== null && !isCorrectLocale(locale))) {
    searchParams.set(KEY_LOCALE, mapLocale(iso3));
  }

  stateUrl.locale = searchParams.get(KEY_LOCALE) as LocaleTypes;
  stateUrl.lang = mapLang(stateUrl.locale);
  stateUrl.country = isRuLang(stateUrl.lang) ? iso3 : '';
  stateUrl.fullUrlParams = `?${searchParams.toString()}`;

  return stateUrl;
};
